/* eslint-disable no-case-declarations */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { ChangeEvent, useMemo, useState, useEffect } from 'react';
import { ListRowProps } from 'react-virtualized';

import useSWR from 'swr';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { usePerformanceMark, Stage } from '@cabify/prom-react';

import AdminSidebar from '../../../../Common/Components/AdminSidebar';
import AdminSidebarAdminItems from '../Components/AdminSidebarAdminItems';
import AdminSidebarCreateUser from './Components/CreateUser';
import AdminSidebarEditUser from './Components/EditUser';

import { UserObject } from '../../../../types/UserObject';
import useUsersPageState from '../../../../utils/state/useUsersPageState';
import SelectBox from '../../../../Common/Components/SelectBox';
import Searchbox from '../../../../Common/Components/Searchbox';

import { SelectBoxItemType } from '../../../../types/SelectBoxPropsType';

import Utils from '../../../../utils/Utils';
import { useAppContext } from '../../../../utils/AppContext';
import { GetAllRequests } from '../../../../types/Fetch';
import { getRequest, getRequestAll } from '../../../../utils/fetch';
import { sensitySystemsOrgId } from '../../../../utils/constants';
import AdminSidebarResetPwdUser from './Components/ResetPwdUser';
import AdminSidebarSuspendUser from './Components/SuspendUser';
import AdminSidebarEnableMfaUser from './Components/EnableMfaUser';
import AdminSidebarDisableMfaUser from './Components/DisableMfaUser';
import AdminSidebarDeleteUser from './Components/DeleteUser';
import AdminSidebarReactivateUser from './Components/ReactivateUser';
import AdminSidebarHandleAPIKey from './Components/AdminSidebarGenerateAPIKey';

import { UsersPageProps } from '../../../../types/UsersPageProps';
import { CustomerObject } from '../../../../types/CustomerObject';

import Table from '../../../../Common/Components/Table';
import Button from '../../../../Common/Components/Button';
import { TableFiltering } from '../../../../types/table';
import TableToolbar from '../Components/TableToolbar';
import TableToolbarSubtitle from '../Components/TableToolbarSubtitle';

import { ReactComponent as EditIcon } from '../../../../img/icons/edit.svg';
import { ReactComponent as ArrowIcon } from '../../../../img/icons/arrow.svg';
import AdminSidebarCopyAPIKey from './Components/CopyApiKey';

function UsersPage(props: UsersPageProps): JSX.Element {
  const { type = 'active', location: { state: locationState } = {} } = props;
  const { addNotification } = useAppContext();
  const {
    headers,
    headersType,
    selectedUsers,
    setSelectedUsers,
    toggleCreateUser,
    setToggleCreateUser,
    toggleEditUser,
    setToggleEditUser,
    selectedPartner,
    setSelectedPartner,
    selectedAccount,
    setSelectedAccount,
  } = useUsersPageState(locationState);

  const listWidth = 200;
  const listRowHeight = 41;
  const searchBoxMaxItems = 10;
  const selectBoxWidth = 140;
  const selectBoxMaxItems = 7;

  const { data: customerResp, mutate: updateCustomers } = useSWR<Map<string, CustomerObject>>(['/organizations', 'UsersPage'], getRequest, { compare: () => false });
  const allCustomers = useMemo(() => {
    const tmpMap = new Map();
    customerResp?.forEach((customer) => {
      tmpMap.set(customer.orgid, customer);
    });
    return tmpMap;
  }, [customerResp]);

  const partnersList = useMemo(() => (allCustomers && allCustomers.size > 0 ? Array.from(allCustomers.values()).filter((customer: CustomerObject) => customer.type === 'PARTNER' || customer.type === 'ROOT').sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) : []), [allCustomers]);
  const customersList = useMemo(() => (allCustomers && allCustomers.size > 0 ? Array.from(allCustomers.values()).filter((customer: CustomerObject) => customer.type !== 'PARTNER').sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) : []), [allCustomers]);
  const partnersListForNonAdmin = useMemo(() => (allCustomers && allCustomers.size > 0 ? Array.from(allCustomers.values()).filter((customer: CustomerObject) => customer.type === 'PARTNER').sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) : []), [allCustomers]);

  const [isLoaded, setIsLoaded] = useState(false);
  const [users, setUsers] = useState<UserObject[]>();
  const [editUser, setEditUser] = useState<UserObject>({} as UserObject);
  const [searchPartner, setSearchPartner] = useState(selectedPartner.name || '');
  const [filteredPartnerList, setFilteredPartnerList] = useState(partnersList);
  const [searchAccount, setSearchAccount] = useState(selectedAccount.name || '');
  const [filteredAccountList, setFilteredAccountList] = useState(customersList);
  const [filteredPartnersAccountList, setFilteredPartnersAccountList] = useState(customersList);
  const [selectedAction, setSelectedAction] = useState<SelectBoxItemType>({ title: '', key: '' });
  const [openModal, setOpenModal] = useState(0);
  const [copyActivatedKey, setCopyActivatedKey] = useState<string>('');

  const modalResetPwd = 1;
  const modalEnableMfa = 2;
  const modalDisableMfa = 3;
  const modalSuspendUser = 4;
  const modalDeleteUser = 5;
  const modalReactivateUser = 6;
  const modalDeleteInactiveUser = 7;
  const modalCreateAPIKey = 10;
  const modalDeactiveAPIKey = 11;
  const modalCopyAPIKey = 12;

  const actionsList = type === 'active' ? [
    { title: 'Reset password', key: '0' },
    { title: 'Enable MFA', key: '1' },
    { title: 'Disable MFA', key: '2' },
    { title: 'Suspend access', key: '3' },
    { title: 'Delete', key: '4' },
  ] : [
    { title: 'Restore access', key: '5' },
    { title: 'Delete', key: '6' },
  ];

  const isSensityUserAdmin = Utils.isSensityUserAdmin();
  const isSensityUser = Utils.isVerizonUser();
  const isSensityAdmin = Utils.isSensityAdmin();
  const isPartnerUserAdmin = Utils.isPartnerUserAdmin();
  const isEndUserAdmin = Utils.isEndUserAdmin();
  const isAdminUser = Utils.isAdminUser();

  const handlePartnerListChange = (name: string) => {
    setSearchPartner(name);

    if (name === '') {
      setSelectedPartner({ id: '', name: '' });
      setFilteredPartnerList(partnersList);
    } else {
      setFilteredPartnerList(partnersList?.filter((item: CustomerObject) => item.name.toLowerCase().includes(name.toLowerCase())));
    }
  };

  const handleAccountListChange = (name: string) => {
    setSearchAccount(name);

    if (name === '') {
      setSelectedAccount({ id: '', name: '' });
      setFilteredAccountList(customersList);
      setSelectedPartner({ id: '', name: '' });
      setFilteredPartnerList(partnersList);
      setSearchPartner('');
    } else {
      const filterList = selectedPartner.id ? filteredPartnersAccountList : customersList;
      setFilteredAccountList(filterList?.filter((item: CustomerObject) => item.name.toLowerCase().includes(name.toLowerCase())));
    }
  };

  const partnerRowRenderer = ({ key, index, style }: ListRowProps, setToggle: React.Dispatch<React.SetStateAction<boolean>>) => {
    if (!filteredPartnerList) {
      return <></>;
    }

    const { name } = filteredPartnerList[index];

    return (
      <li
        key={key}
        style={style}
        className="search-partner--list"
        onClick={() => {
          setToggle(false);
          setSearchPartner(name);
          setSelectedPartner({ id: filteredPartnerList[index].orgid, name });
          setSelectedUsers(new Map());
        }}
      >
        <span className={name === selectedPartner.name ? 'selected' : ''}>{name || ''}</span>
      </li>
    );
  };

  const accountRowRenderer = ({ key, index, style }: ListRowProps, setToggle: React.Dispatch<React.SetStateAction<boolean>>) => {
    if (!filteredAccountList) {
      return <></>;
    }

    const { name } = filteredAccountList[index];

    return (
      <li
        key={key}
        style={style}
        className="search-partner--list"
        onClick={() => {
          setToggle(false);
          setSearchAccount(name);
          setSelectedAccount({ id: filteredAccountList[index].orgid, name });
          setSelectedUsers(new Map());
        }}
      >
        <span className={name === selectedAccount.name ? 'selected' : ''}>{name || ''}</span>
      </li>
    );
  };

  useEffect(() => {
    setFilteredPartnerList(partnersList);
  }, [partnersList]);

  useEffect(() => {
    setFilteredAccountList(customersList);
  }, [customersList]);

  useEffect(() => {
    if (selectedPartner.id !== '') {
      setSearchAccount('');
      const partnersAccounts = customersList.filter((customer) => customer.po === selectedPartner.id);
      setFilteredAccountList(partnersAccounts);
      setFilteredPartnersAccountList(partnersAccounts);
    }
    if (selectedAccount.id !== '' && allCustomers) {
      setSearchAccount(selectedAccount.name);
      const filteredSelectedAccount = customersList.filter((customer) => customer.orgid === selectedAccount.id);
      const filteredSelectedAccountPartner = partnersList.filter((partner) => partner.orgid === filteredSelectedAccount[0].po);
      if (filteredSelectedAccountPartner[0] !== undefined) {
        setSearchPartner(filteredSelectedAccountPartner[0].name);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPartner, selectedAccount, allCustomers]);

  const initialFiltering: TableFiltering | undefined = useMemo(() => {
    if (!isSensityUser) {
      if (locationState?.partnerName && headers.some((header) => header.key === 'tablePartnerName')) {
        return { tablePartnerName: locationState.partnerName };
      }

      if (locationState?.accountName && headers.some((header) => header.key === 'tableAccountName')) {
        return { tableAccountName: locationState.accountName };
      }
    }

    return undefined;
  }, [isSensityUser, locationState, headers]);

  // Users
  useEffect(() => {
    const requests: GetAllRequests = [];
    const userAccounts : CustomerObject[] = [];

    if (allCustomers && allCustomers.size > 0) {
      const userPath = type === 'suspended' ? 'suspended-users' : 'users';

      if (isSensityUser) {
        const orgId = selectedAccount.id || selectedPartner.id || '';

        if (orgId) {
          const accountInfo = allCustomers.get(orgId);
          requests.push({ path: `/organizations/${orgId}/${userPath}` });
          userAccounts.push(accountInfo);
        }
      } else {
        allCustomers.forEach((customer) => {
          requests.push({ path: `/organizations/${customer.orgid}/${userPath}` });
          userAccounts.push(customer);
        });
      }

      if (requests.length > 0) {
        let mergedList: UserObject[] = [];

        getRequestAll(requests)
          .then((responses) => {
            responses.results.forEach((resp, index) => {
              if (resp.status === 'fulfilled') {
                const usersList = resp.value;

                let rolesType = 'enduserroles';
                let partnerName = '';
                let accountName = '';
                let tablePartnerName = '--';
                let tableAccountName = '--';
                let accountId = '';

                if (isSensityUser && selectedAccount.id === sensitySystemsOrgId) {
                  accountName = selectedAccount.name;
                  tableAccountName = selectedAccount.name;
                  accountId = selectedAccount.id;
                  rolesType = 'sensityroles';
                } else {
                  const account = userAccounts[index];
                  accountId = account.orgid;
                  if (account.type === 'ROOT') {
                    accountName = account.name;
                    tableAccountName = account.name;
                    rolesType = 'sensityroles';
                  } else if (account.type === 'PARTNER') {
                    partnerName = account.name;
                    tablePartnerName = account.name;
                    rolesType = 'partnerroles';
                  } else {
                    partnerName = allCustomers.get(account.po)?.name;
                    tablePartnerName = allCustomers.get(account.po)?.name;
                    accountName = account.name;
                    tableAccountName = account.name;
                  }
                }

                usersList.forEach((user: UserObject) => {
                  user.mfa = user.mfa ? 'Enabled' : 'Disabled';
                  user.rolesTitle = Utils.getUserRoleTitle(user.roles);
                  user.roledetails = rolesType;
                  user.account = accountName;
                  user.partner = partnerName;
                  user.tablePartnerName = tablePartnerName;
                  user.tableAccountName = tableAccountName;
                  user.accountid = accountId;

                  if (type === 'active') {
                    user.edit = (
                      <div className="usermanager-actions">
                        <EditIcon
                          width="14"
                          height="14"
                          onClick={() => {
                            setEditUser(user);
                            setToggleEditUser(true);
                          }}
                        />
                      </div>
                    );
                  }
                });

                mergedList = [...mergedList, ...usersList];
              }
            });

            setUsers(mergedList);
            setIsLoaded(true);
          });
      } else {
        setUsers([]);
        setIsLoaded(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCustomers, partnersList, customersList, selectedPartner, selectedAccount]);

  useEffect(() => {
    if (selectedAction.key !== '') {
      if (selectedUsers.size === 0) {
        addNotification({ type: 'error', message: 'Please select one or more users for the action.' });
      } else {
        const modalId = parseInt(selectedAction.key, 10) + 1;

        if (modalId === modalEnableMfa) {
          let emptyMfaVal = 0;

          if (selectedUsers instanceof Map) {
            selectedUsers.forEach((user: UserObject) => {
              if (user.phone === '') {
                emptyMfaVal += 1;
              }
            });
          }

          if (emptyMfaVal > 0) {
            addNotification({ type: 'error', message: 'One or more users does not have a mobile (SMS) number specified. Please enter mobile numbers or change your selection.' });
          } else {
            setIsLoaded(true);
            setOpenModal(modalId);
          }
        } else {
          setIsLoaded(true);
          setOpenModal(modalId);
        }
      }

      setSelectedAction({ title: '', key: '' });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers, selectedAction]);

  const pageTitle = `${type.charAt(0).toUpperCase()}${type.slice(1)} users`;

  const colWidthCalcFn = (col: number, width: number): number => {
    const checkboxWidth = 47;
    const margin = 5;
    const { checkbox, edit, mobile, mfa } = { checkbox: isAdminUser ? checkboxWidth : 0, edit: 47, mobile: 150, mfa: 110 };

    let colWidth: number;
    let doubleColWidth: number;
    width -= margin;
    const remainColVerizonAndPartner = 6;
    const remainColEndUserAdmin = 4;

    const checkboxArr = isAdminUser ? [checkbox] : [];
    switch (headersType) {
      case 'UsersVerizonAdminMain':
        colWidth = (width - checkbox - edit - mobile - mfa) / remainColVerizonAndPartner;
        doubleColWidth = colWidth * 2;
        // ------- checkbox ---- email ------ edit - name ---- role -- mobile - mfa - account - partner
        return [...checkboxArr, doubleColWidth, edit, colWidth, colWidth, mobile, mfa, colWidth, colWidth][col];
      case 'UsersVerizonNonAdminMain':
        colWidth = (width - mobile - mfa) / remainColVerizonAndPartner;
        doubleColWidth = colWidth * 2;
        // ---- email ------- name ---- role -- mobile - mfa - account - partner
        return [doubleColWidth, colWidth, colWidth, mobile, mfa, colWidth, colWidth][col];
      case 'UsersPartnerAdminMain':
        colWidth = (width - checkbox - edit - mobile - mfa) / remainColVerizonAndPartner;
        doubleColWidth = colWidth * 2;
        // --------- checkbox -- email ----- edit - name ---- role -- mobile - mfa - account - partner
        return [...checkboxArr, doubleColWidth, edit, colWidth, colWidth, mobile, mfa, colWidth, colWidth][col];
      case 'UsersPartnerNonAdminMain':
        colWidth = (width - mobile - mfa) / remainColVerizonAndPartner;
        doubleColWidth = colWidth * 2;
        // ---- email ------- name ---- role -- mobile - mfa - account - partner
        return [doubleColWidth, colWidth, colWidth, mobile, mfa, colWidth, colWidth][col];
      case 'UsersEndUserAdminMain':
        colWidth = (width - checkbox - edit - mobile - mfa) / remainColEndUserAdmin;
        doubleColWidth = colWidth * 2;
        // --------- checkbox -- email ----- edit - name ---- role -- mobile - mfa
        return [...checkboxArr, doubleColWidth, edit, colWidth, colWidth, mobile, mfa][col];
      case 'UsersEndUserNonAdminMain':
        colWidth = (width - mobile - mfa) / remainColEndUserAdmin;
        doubleColWidth = colWidth * 2;
        // ---- email ------- name ---- role -- mobile - mfa
        return [doubleColWidth, colWidth, colWidth, mobile, mfa][col];
      default:
        return 0;
    }
  };

  // usePerformanceMark(isLoading ? Stage.Usable : Stage.Complete, 'Users');
  const [filteredTableData, setFilteredTableData] = useState([]);

  return (
    <div className="config__content users">
      <AdminSidebar title="Account admin">
        <AdminSidebarAdminItems path={window.location.pathname} />
      </AdminSidebar>
      <div className="page-content users-content">
        <TableToolbar
          addClass="users"
          title={pageTitle}
          subtitle={<TableToolbarSubtitle listLength={users?.length || 0} download={() => Utils.downloadCSV(headers.filter((header) => !['rowSelectCheckbox', 'edit'].includes(header.key)), filteredTableData || [], 'users')} />}
        >
          <div className={`tabletoolbar__children ${!isSensityUser ? 'no-border' : ''}`}>
            {isSensityUser && (
            <div className="search-partner-and-account margin-right-auto">
              <Searchbox
                type="light"
                list={filteredPartnerList || []}
                title="Select partner"
                icon={<ArrowIcon className="search__icon" />}
                alwaysShowIcon
                onChange={(event: ChangeEvent<HTMLInputElement>) => handlePartnerListChange(event.target.value)}
                searchValue={searchPartner}
                listWidth={listWidth}
                listRowHeight={listRowHeight}
                listRowRenderer={partnerRowRenderer}
                rowNumber={searchBoxMaxItems}
                iconClickToggleList
                className="search-partner"
                listStyle={{ position: 'absolute' }}
              />
              <Searchbox
                type="light"
                list={filteredAccountList || []}
                title="Select account"
                icon={<ArrowIcon className="search__icon" />}
                alwaysShowIcon
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleAccountListChange(event.target.value)}
                searchValue={searchAccount}
                listWidth={listWidth}
                listRowHeight={listRowHeight}
                listRowRenderer={accountRowRenderer}
                rowNumber={searchBoxMaxItems}
                iconClickToggleList
                className="search-partner last"
                listStyle={{ position: 'absolute' }}
              />
            </div>
            )}
            {isLoaded && isAdminUser && (
              <div className="user-action">
                <SelectBox
                  onClick={(item: SelectBoxItemType) => {
                    if ((item.key === (modalCreateAPIKey - 1).toString() || item.key === (modalDeactiveAPIKey - 1).toString())
                    && selectedUsers.size > 1) {
                      addNotification({ type: 'error', message: 'Please select only one user for the action.' });
                    } else {
                      setSelectedAction(item);
                    }
                  }}
                  list={Utils.arrayToSelectbox(actionsList || [], 'key', 'title')}
                  title="Actions"
                  type="light"
                  listWidth={selectBoxWidth}
                  maxItems={selectBoxMaxItems}
                />
              </div>
            )}
            {isAdminUser
              && (
              <div className="tabletoolbar__children-btn">
                <Button onClick={() => setToggleCreateUser(true)} label="Create new" buttonType="secondary" />
              </div>
              )}
          </div>
        </TableToolbar>
        {(isSensityUser && selectedPartner.id === '' && selectedAccount.id === '') ? (
          <div className="table table--light select-partner-account">
            <div className="item">Select a Partner or an Account to get started.</div>
          </div>
        ) : (
          <div className="table table--light auto-height">
            <Table
              headers={headers.filter((header) => (isAdminUser ? !header.isHidden : !header.isHidden && header.key && header.key !== 'rowSelectCheckbox'))}
              data={users}
              setFilteredTableData={setFilteredTableData}
              initialFiltering={initialFiltering}
              selectedItems={selectedUsers}
              setSelectedItems={setSelectedUsers}
              skipCellMeasure
              colWidthCalcFn={colWidthCalcFn}
              autoheight
            />
          </div>
        )}
      </div>
      {toggleCreateUser && (
      <AdminSidebarCreateUser
        modalOpen={setToggleCreateUser}
        partnersList={(isSensityAdmin) ? partnersList : partnersListForNonAdmin || []}
        customersList={customersList || []}
        updateCustomers={updateCustomers}
        isSensityUserAdmin={isSensityUserAdmin}
        isPartnerUserAdmin={isPartnerUserAdmin}
        isEndUserAdmin={isEndUserAdmin}
      />
      )}
      {toggleEditUser && (
      <AdminSidebarEditUser
        modalOpen={setToggleEditUser}
        updateCustomers={updateCustomers}
        editUser={editUser}
      />
      )}
      {(isLoaded && openModal === modalResetPwd) && (
        <AdminSidebarResetPwdUser
          setOpenModal={setOpenModal}
          selectedUsers={selectedUsers}
          setLoader={() => setIsLoaded(false)}
        />
      )}
      {(isLoaded && openModal === modalEnableMfa) && (
        <AdminSidebarEnableMfaUser
          setOpenModal={setOpenModal}
          selectedUsers={selectedUsers}
          updateCustomers={updateCustomers}
          setLoader={() => setIsLoaded(false)}
        />
      )}
      {(isLoaded && openModal === modalDisableMfa) && (
        <AdminSidebarDisableMfaUser
          setOpenModal={setOpenModal}
          selectedUsers={selectedUsers}
          updateCustomers={updateCustomers}
          setLoader={() => setIsLoaded(false)}
        />
      )}
      {(isLoaded && openModal === modalSuspendUser) && (
        <AdminSidebarSuspendUser
          setOpenModal={setOpenModal}
          selectedUsers={selectedUsers}
          updateCustomers={updateCustomers}
          setLoader={() => setIsLoaded(false)}
        />
      )}
      {(isLoaded && openModal === modalDeleteUser) && (
      <AdminSidebarDeleteUser
        setOpenModal={setOpenModal}
        selectedUsers={selectedUsers}
        updateCustomers={updateCustomers}
        setLoader={() => setIsLoaded(false)}
      />
      )}
      {(isLoaded && openModal === modalReactivateUser) && (
      <AdminSidebarReactivateUser
        setOpenModal={setOpenModal}
        selectedUsers={selectedUsers}
        updateCustomers={updateCustomers}
        setLoader={() => setIsLoaded(false)}
      />
      )}
      {(isLoaded && openModal === modalDeleteInactiveUser) && (
      <AdminSidebarDeleteUser
        setOpenModal={setOpenModal}
        selectedUsers={selectedUsers}
        updateCustomers={updateCustomers}
        setLoader={() => setIsLoaded(false)}
      />
      )}
      {(isLoaded && openModal === modalCreateAPIKey) && (
      <AdminSidebarHandleAPIKey
        setOpenModal={setOpenModal}
        setCopyActivatedKey={setCopyActivatedKey}
        selectedUser={selectedUsers.get(Array.from(selectedUsers.keys())[0])}
        operation="Generate API key"
        text="Are you sure you want to create an API key for this user?"
      />
      )}
      {(isLoaded && openModal === modalDeactiveAPIKey) && (
      <AdminSidebarHandleAPIKey
        setOpenModal={setOpenModal}
        setCopyActivatedKey={setCopyActivatedKey}
        selectedUser={selectedUsers.get(Array.from(selectedUsers.keys())[0])}
        operation="Deactivate API key"
        text="Are you sure you want to deactivate the API key for this user?"
      />
      )}
      {(isLoaded && openModal === modalCopyAPIKey) && (
      <AdminSidebarCopyAPIKey
        setOpenModal={setOpenModal}
        copyApiKey={copyActivatedKey}
      />
      )}

    </div>
  );
}

export default UsersPage;
