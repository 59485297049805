import React from 'react';
import Modal from '../../../../../Common/Components/Modal';
import { AdminSidebarDeleteUserProps } from '../../../../../types/AdminSidebarDeleteUserProps';
import { useAppContext } from '../../../../../utils/AppContext';
import { deleteRequestAll } from '../../../../../utils/fetch';
import { ReactComponent as WarningIcon } from '../../../../../img/icons/warning.svg';

function AdminSidebarDeleteUser(props: AdminSidebarDeleteUserProps): JSX.Element {
  const {
    setOpenModal,
    selectedUsers,
    updateCustomers,
    setLoader } = props;

  const { addNotification } = useAppContext();

  return (
    <Modal
      setModalOpen={() => setOpenModal(0)}
      className="admin-user-modal"
      title="Delete user"
      primaryButtonAction={async () => {
        setOpenModal(0);
        setLoader();
        if (selectedUsers !== undefined) {
          try {
            const requests: { path: string }[] = Array.from(selectedUsers.values()).map(
              (value) => ({
                path: `/organizations/${value.accountid}/users/${value.userid}`,
              }),
            );
            deleteRequestAll(requests)
              .then((results) => {
                if (results.resolved === requests.length) {
                  addNotification({ type: 'info', message: 'Users have been successfully deleted.' });
                  if (updateCustomers) {
                    updateCustomers();
                    selectedUsers.clear();
                  }
                } else {
                  addNotification({ type: 'error', message: 'Your "Delete users" operation has failed.' });
                }
              });
          } catch (e) {
            addNotification({ type: 'error', message: 'Your "Delete users" operation has failed.' });
          }
        }
      }}
      primaryButtonLabel="Apply"
      secondaryButtonAction={() => setOpenModal(0)}
      secondaryButtonLabel="Cancel"
      borderTopColor="yellow"
    >
      <div className="admin-user-modal-content">
        <WarningIcon className="admin-user-modal-content-icon" />
        Are you sure you want to delete these users?
      </div>
    </Modal>
  );
}

export default AdminSidebarDeleteUser;
