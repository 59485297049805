import React from 'react';
import Modal from '../../../../../Common/Components/Modal';
import { AdminSidebarSuspendUserProps } from '../../../../../types/AdminSidebarSuspendUserProps';
import { useAppContext } from '../../../../../utils/AppContext';
import { postRequestAll } from '../../../../../utils/fetch';
import { ReactComponent as WarningIcon } from '../../../../../img/icons/warning.svg';

function AdminSidebarSuspendUser(props: AdminSidebarSuspendUserProps): JSX.Element {
  const {
    setOpenModal,
    selectedUsers,
    updateCustomers,
    setLoader } = props;

  const { addNotification } = useAppContext();

  return (
    <Modal
      setModalOpen={() => setOpenModal(0)}
      className="admin-user-modal"
      title="Suspend access"
      primaryButtonAction={async () => {
        setOpenModal(0);
        setLoader();
        if (selectedUsers !== undefined) {
          try {
            const requests: { path: string}[] = Array.from(selectedUsers.values()).map(
              (value) => ({
                path: `/organizations/${value.accountid}/suspended-users/${value.userid}`,
              }),
            );
            postRequestAll(requests)
              .then((results) => {
                if (results.resolved === requests.length) {
                  addNotification({ type: 'success', message: 'User access has been successfully suspended. User(s) have been notified by email.' });
                  if (updateCustomers) {
                    updateCustomers();
                  }
                } else {
                  addNotification({ type: 'error', message: 'Your "Suspend user" operation has failed.' });
                }
              });
          } catch (e) {
            addNotification({ type: 'error', message: 'Your "Suspend user" operation has failed.' });
          }
        }
      }}
      primaryButtonLabel="Apply"
      secondaryButtonAction={() => setOpenModal(0)}
      secondaryButtonLabel="Cancel"
      borderTopColor="yellow"
    >
      <div className="admin-user-modal-content">
        <WarningIcon className="admin-user-modal-content-icon" />
        Are you sure you want to suspend access for these users?
      </div>
    </Modal>
  );
}

export default AdminSidebarSuspendUser;
