/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DroppableProvided, DropResult, NotDraggingStyle } from 'react-beautiful-dnd';
import { ReactComponent as DnDIcon } from '../../img/icons/drag-and-drop.svg';
import { ReactComponent as FilterIcon } from '../../img/icons/filter.svg';
import { ReactComponent as DragArrowsIcon } from '../../img/icons/sort-arrows-big.svg';
import { ReactComponent as SortArrowsIcon } from '../../img/icons/sort-arrows.svg';
import { SelectBoxItemType } from '../../types/SelectBoxPropsType';
import { CustomFieldItem, TableHeadersProp } from '../../types/TableHeadersProp';
import SelectBox from './SelectBox';
import Tooltip from './Tooltip';

function DraggableBoard(props: {
  headers: TableHeadersProp[],
  customHeaders: TableHeadersProp[],
  defaultHeaders: TableHeadersProp[],
  setEditedHeaders: React.Dispatch<React.SetStateAction<TableHeadersProp[]>>
}): JSX.Element {
  const { headers, customHeaders, setEditedHeaders, defaultHeaders } = props;
  const maxAllowedCustomAttributes = 20;
  const id2List = {
    droppable: 'selected',
    droppable2: 'items',
  };

  const selectOptions = [
    { title: 'All', key: 'all', filterFn: (i: CustomFieldItem) => true },
    { title: 'Node attributes', key: 'node_fields', filterFn: (i: CustomFieldItem) => !i.isCustom },
    { title: 'Custom fields', key: 'custom_fields', filterFn: (i: CustomFieldItem) => i.isCustom },
  ];

  const getFieldItem = (h: TableHeadersProp) => (
    {
      id: h.key,
      content: h.val || h.key,
      isCustom: h.key.startsWith('customAttributes_'),
      isFixed: h.isCustomizable === false,
      originalHeader: h,
    } as CustomFieldItem);

  const [state, setState] = useState({
    sortBy: '',
    selected: (headers as TableHeadersProp[])
      .map(getFieldItem).sort((a, b) => ((a.isFixed === b.isFixed) ? 0 : a.isFixed ? -1 : 1)),
    items: ([...defaultHeaders, ...customHeaders] as TableHeadersProp[])
      .filter((h) => !headers.map((i) => i.key).includes(h.key))
      .map(getFieldItem).sort((a, b) => ((a.isFixed === b.isFixed) ? 0 : a.isFixed ? -1 : 1)),
  });

  useEffect(() => {
    setEditedHeaders(state.selected.map((i) => (i.originalHeader)));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const getList = (id: 'droppable' | 'droppable2') => {
    const a = id2List[id] as 'items'| 'selected';
    return state[a] as CustomFieldItem[];
  };

  const [filter, setFilter] = useState<string>();

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId as 'droppable' | 'droppable2'),
        source.index,
        destination.index,
      ) as CustomFieldItem[];

      let stateP;

      if (source.droppableId === 'droppable2') {
        stateP = { ...state, ...{ items } };
      } else {
        stateP = { ...state, ...{ selected: items } };
      }

      setState(stateP);
    } else {
      const resultP = move(
        getList(source.droppableId as 'droppable' | 'droppable2'),
        getList(destination.droppableId as 'droppable' | 'droppable2'),
        source,
        destination,
      );
      if (destination.droppableId === 'droppable2') {
        setState({
          sortBy: '',
          selected: resultP.source,
          items: resultP.destination,
        });
      } else {
        setState({
          sortBy: '',
          selected: resultP.destination,
          items: resultP.source,
        });
      }
    }
  };

  const reorder = (list: CustomFieldItem[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    if (list.filter((i) => i.isFixed).length < endIndex + 1) {
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
    }
    return result as CustomFieldItem[];
  };

  const move = (
    source: CustomFieldItem[],
    destination: CustomFieldItem[],
    droppableSource: { index: number; droppableId: string; },
    droppableDestination: { index: number; droppableId: string; },
  ):
     { source: CustomFieldItem[], destination: CustomFieldItem[] } => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    if (destination.filter((i) => i.isFixed).length < droppableDestination.index + 1) {
      const [removed] = sourceClone.splice(droppableSource.index, 1);
      destClone.splice(droppableDestination.index, 0, removed);
    }
    const result = { source: sourceClone, destination: destClone };
    return result;
  };

  const grid = 6;

  const getIconStyle = (isCustom: boolean, sourceDragableId: string, draggingOver?: string) => {
    const hiddenCustomIconColor = '#99CFEB';
    const visibleDefaulIconColor = '#747676';
    const visibleCustomIconColor = '#66B7E1';
    const hiddenDefaultIconColor = '#AFAFAF';

    const isHidden = calculateIsHidden(sourceDragableId, draggingOver);

    const color = isCustom
      ? (isHidden ? hiddenCustomIconColor : visibleCustomIconColor)
      : (isHidden ? hiddenDefaultIconColor : visibleDefaulIconColor);

    return { fill: color, marginRight: '10px' };
  };

  const getItemStyle = (sourceDragableId: string, draggableStyle?: NotDraggingStyle, draggingOver?: string, isCustom?: boolean, isFixed?:boolean) => {
    const hiddenCustomFieldColor = '#339FD7';
    const visibleDefaultFieldColor = '#D8DADA';
    const visibleCustomFieldColor = '#66B7E1';
    const hiddenDefaultFieldColor = '#000000';

    const hiddenCustomBgColor = '#F2F9FD';
    const hiddenDefaultBgColor = '#FFFFFF';
    const visibleCustomBgColor = '#424242';
    const visibleDefaultBgColor = '#535353';

    const hiddenCustomBorderColor = '#CCE7F5';
    const hiddenDefaultBorderColor = '#D8DADA';
    const visibleCustomBorderColor = '#747676';
    const visibleDefaultBorderColor = '#747676';

    const isHidden = calculateIsHidden(sourceDragableId, draggingOver);

    return {
      ...draggableStyle,
      transform: isFixed ? 'none' : draggableStyle?.transform,
      paddingLeft: isFixed ? (24 + grid * 2) : (grid * 2),
      paddingRight: grid * 2,
      background: isCustom
        ? (isHidden ? hiddenCustomBgColor : visibleCustomBgColor)
        : (isHidden ? hiddenDefaultBgColor : visibleDefaultBgColor),
      border: `1px solid ${
        isCustom
          ? (isHidden ? hiddenCustomBorderColor : visibleCustomBorderColor)
          : (isHidden ? hiddenDefaultBorderColor : visibleDefaultBorderColor)}`,
      color: isCustom
        ? (isHidden ? hiddenCustomFieldColor : visibleCustomFieldColor)
        : (isHidden ? hiddenDefaultFieldColor : visibleDefaultFieldColor),
    } as {[index: string]: string | number};
  };

  function calculateIsHidden(sourceDragableId: string, draggingOver: string | undefined) {
    return ((sourceDragableId === 'droppable2' && !draggingOver)
      || (draggingOver && draggingOver === 'droppable2'));
  }

  function getTypeInfoStyle(sourceDragableId: string, draggingOver?: string): React.CSSProperties | undefined {
    const isHidden = calculateIsHidden(sourceDragableId, draggingOver);
    return { color: isHidden ? '#D8DADA' : '#747676' };
  }

  const getDroppableList = (id: string, items: CustomFieldItem[], provided: DroppableProvided) => (
    items.length
      ? (
        <div
          className={`customize-fields__list-scrollable-box ${id === 'droppable2' ? 'margin-left-50 customize-fields__empty-list' : ''}`}
        >
          <div>
            {items.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id}
                index={index}
                isDragDisabled={item.isFixed || (id === 'droppable2' && item.isCustom && state.selected.filter((i) => i.isCustom).length >= maxAllowedCustomAttributes)}
              >
                {(providedP, snapshotP) => (
                  <Tooltip
                    position="auto-start"
                    text={`Max ${maxAllowedCustomAttributes} custom attributes columns allowed`}
                    disabled={!item.isCustom || state.selected.filter((i) => i.isCustom).length < maxAllowedCustomAttributes}
                  >
                    <div
                      ref={providedP.innerRef}
                      {...providedP.draggableProps}
                      {...providedP.dragHandleProps}
                      className="customize-fields__list-item"
                      style={getItemStyle(
                        id,
                        providedP.draggableProps.style,
                        snapshotP.draggingOver,
                        item.isCustom,
                        item.isFixed,
                      )}
                    >
                      {!item.isFixed && <DnDIcon style={getIconStyle(item.isCustom, id, snapshotP.draggingOver)} />}
                      {item.content}
                      {item.isFixed
                    && (
                    <span className="customize-fields__list-item-type">
                      Fixed
                    </span>
                    )}
                      {item.isCustom
                    && (
                    <span className="customize-fields__list-item-type" style={getTypeInfoStyle(id, snapshotP.draggingOver)}>
                      Custom
                      <br />
                      attribute
                    </span>
                    )}
                    </div>
                  </Tooltip>
                )}
              </Draggable>

            ))}
            {provided.placeholder}
          </div>
        </div>
      )
      : (
        <div className="customize-fields__empty-list">
          <span>To hide columns from display drag-n-drop columns here.</span>
        </div>
      )
  );

  const getHiddenItems = (itemsP: CustomFieldItem[]) => itemsP.filter(selectOptions.find((i) => i.key === filter)?.filterFn || (() => true));
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, _) => (
          <div className="customize-fields__list">
            <h2 className="customize-fields__list-title">Displayed columns</h2>
            <h2 className="customize-fields__list-subtitle">Re-arrange order of the columns that appear in the nodes list.</h2>
            <div ref={provided.innerRef}>{getDroppableList('droppable', state.selected, provided)}</div>
            <div className="customize-fields__list-info-text">
              <span className="customize-fields__list-info-text-item">
                Total
                &nbsp;
                {state.selected.length}
                &nbsp;
                columns
              </span>
              <span className="customize-fields__list-info-text-item customize-fields__list-info-text-item--left">
                {state.selected.filter((i) => !i.isCustom).length}
                &nbsp;
                node attributes
              </span>
            </div>
            <div className="customize-fields__list-info-text">
              <span className="customize-fields__list-info-text-item customize-fields__list-info-text-item--left">
                {state.selected.filter((i) => i.isCustom).length}
                &nbsp;
                custom attributes
              </span>
            </div>
          </div>
        )}
      </Droppable>
      <div className="customize-fields__dnd-icon">
        <DragArrowsIcon />
        <span className="customize-fields__dnd-icon-text">Drag-n-drop</span>
      </div>
      <Droppable droppableId="droppable2">
        {(provided, _) => (
          <div className="customize-fields__list">
            <h2 className="customize-fields__list-title margin-left-50 margin-bottom-7">Hidden columns</h2>
            { getHiddenItems(state.items).length !== 0 && (
            <div className="customize-fields__list-toolbar">
              <SelectBox
                type="light"
                listWidth={200}
                icon={<FilterIcon />}
                list={selectOptions}
                onClick={(item: SelectBoxItemType) => {
                  setFilter(item.key);
                }}
                title={selectOptions.find((i) => i.key === filter)?.title || 'Filter columns'}
                selectedItemKey={filter}
                displayArrow={false}
                isHorizontal
              />
              <SortArrowsIcon
                onClick={() => {
                  setState((oldState) => {
                    const sorted = [...oldState.items].sort((item1, item2) => item1.content.localeCompare(item2.content));
                    const newSorted = oldState.sortBy === '' || oldState.sortBy === 'desc' ? 'asc' : 'desc';
                    if (newSorted === 'desc') {
                      sorted.reverse();
                    }

                    return {
                      sortBy: newSorted,
                      selected: state.selected,
                      items: sorted,
                    };
                  });
                }}
              />
            </div>
            )}
            <div ref={provided.innerRef}>
              {getDroppableList(
                'droppable2',
                getHiddenItems(state.items),
                provided,
              )}
            </div>
            { getHiddenItems(state.items).length !== 0
                && (
                <div className="customize-fields__list-info-text margin-left-50">
                  <span className="customize-fields__list-info-text-item">
                    {state.items.length}
                    &nbsp;
                    columns available
                  </span>
                </div>
                )}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DraggableBoard;
