/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import polylabel from '@mapbox/polylabel';
import distance from '@turf/distance';
import * as turf from '@turf/helpers';
import intersect from '@turf/intersect';
import kinks from '@turf/kinks';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';
import { GeoSearchControl, HereProvider } from 'leaflet-geosearch';
import 'leaflet-pixi-overlay'; // Must be called before the 'leaflet' import
import * as PIXI from 'pixi.js';
import L, { LatLng, LatLngBoundsExpression, LeafletMouseEvent } from 'leaflet';
import React from 'react';
import ReactDOM from 'react-dom';
import { renderToString } from 'react-dom/server';

import MultiSelectToolContainer from '../Common/Components/LightingMapMultiSelectTool';
import { ReactComponent as LightAlertCriticalIcon } from '../img/icons/light-alert-critical.svg';
import { ReactComponent as LightAlertMajorIcon } from '../img/icons/light-alert-major.svg';
import { ReactComponent as LightAlertMinorIcon } from '../img/icons/light-alert-minor.svg';
import { ReactComponent as LightAlertOkIcon } from '../img/icons/light-alert-ok.svg';
import { ReactComponent as ScheduleFixed0Icon } from '../img/icons/schedule-fixed-0.svg';
import { ReactComponent as ScheduleFixed100Icon } from '../img/icons/schedule-fixed-100.svg';
import { ReactComponent as SchedulePhotocell0Icon } from '../img/icons/schedule-photocell-0.svg';
import { ReactComponent as SchedulePhotocell100Icon } from '../img/icons/schedule-photocell-100.svg';
import { MapPopupData, MapSearchResult, MultiSelectToolOptions } from '../types/MapProps';
import { NodeObject } from '../types/NodeObject';
import { ScheduleTimelineObject } from '../types/ScheduleTimelineProps';
import { GeoFence } from '../types/SiteObject';
import {
  MARKER_NEUTRAL,
  MARKER_CYAN,
  MARKER_CYAN_SELECTED,
  MARKER_GREEN,
  MARKER_GREEN_SELECTED,
  MARKER_ORANGE,
  MARKER_ORANGE_SELECTED,
  MARKER_PIN,
  MARKER_SCHEDULE_FIXED_0,
  MARKER_SCHEDULE_FIXED_10,
  MARKER_SCHEDULE_FIXED_20,
  MARKER_SCHEDULE_FIXED_30,
  MARKER_SCHEDULE_FIXED_40,
  MARKER_SCHEDULE_FIXED_50,
  MARKER_SCHEDULE_FIXED_60,
  MARKER_SCHEDULE_FIXED_70,
  MARKER_SCHEDULE_FIXED_80,
  MARKER_SCHEDULE_FIXED_90,
  MARKER_SCHEDULE_FIXED_100,
  MARKER_SCHEDULE_PHOTOCELL_0,
  MARKER_SCHEDULE_PHOTOCELL_10,
  MARKER_SCHEDULE_PHOTOCELL_20,
  MARKER_SCHEDULE_PHOTOCELL_30,
  MARKER_SCHEDULE_PHOTOCELL_40,
  MARKER_SCHEDULE_PHOTOCELL_50,
  MARKER_SCHEDULE_PHOTOCELL_60,
  MARKER_SCHEDULE_PHOTOCELL_70,
  MARKER_SCHEDULE_PHOTOCELL_80,
  MARKER_SCHEDULE_PHOTOCELL_90,
  MARKER_SCHEDULE_PHOTOCELL_100,
  MARKER_YELLOW,
  MARKER_YELLOW_SELECTED,
  here,
} from './constants';
import Utils from './Utils';

class MapUtils {
  static generatePopupContent(nodeData: NodeObject): string {
    return renderToString(
      <>
        <div className="map-tooltip-top">
          <span className="map-tooltip-label">ID</span>
          <span className="map-tooltip-nodeid">{nodeData.nodeid}</span>
        </div>
        <div className="map-tooltip-bottom">
          <span className="map-tooltip-label">Name</span>
          <span className="map-tooltip-data">{nodeData.name}</span>
        </div>
        <div className="map-tooltip-bottom">
          <span className="map-tooltip-label">Fixture</span>
          <span className="map-tooltip-data">{nodeData.fixturename}</span>
        </div>
        <div className="map-tooltip-bottom">
          <span className="map-tooltip-label">Schedule</span>
          <span className="map-tooltip-data">{nodeData.schedulename}</span>
        </div>
        <div className="map-tooltip-bottom">
          <span className="map-tooltip-label">Sched. driver level</span>
          <span className="map-tooltip-data">
            {(nodeData.schedDriverLevel || 0).toString()}
            %
          </span>
        </div>
        <div className="map-tooltip-bottom">
          <span className="map-tooltip-label">Last driver level</span>
          <span className="map-tooltip-data">
            {nodeData.senStat}
            %
          </span>
        </div>
        <div className="map-tooltip-bottom">
          <span className="map-tooltip-label">Last driver level report</span>
          <span className="map-tooltip-data">
            {nodeData.ligLastReported}
          </span>
        </div>
      </>,
    );
  }

  static generateScheduleLegend(): JSX.Element {
    return (
      <div className="timelinelegend-container">
        <div className="timelinelegend-section">
          <div className="timelinelegend-section__header">Fixed level</div>
          <div className="timelinelegend-section__item">
            <span>Nodes OFF</span>
            <ScheduleFixed0Icon />
          </div>
          <div className="timelinelegend-section__item">
            <span>Nodes ON</span>
            <ScheduleFixed100Icon />
          </div>
        </div>
        <div className="timelinelegend-section">
          <div className="timelinelegend-section__header">Photocell-controlled</div>
          <div className="timelinelegend-section__item">
            <span>Nodes OFF</span>
            <SchedulePhotocell0Icon />
          </div>
          <div className="timelinelegend-section__item">
            <span>Nodes ON</span>
            <SchedulePhotocell100Icon />
          </div>
        </div>
      </div>
    );
  }

  static generateAlertsLegend(): JSX.Element {
    return (
      <div className="timelinelegend-container">
        <div className="timelinelegend-section">
          <div className="timelinelegend-section__header">Node status</div>
          <div className="timelinelegend-section__item">
            <span>Everything OK</span>
            <LightAlertOkIcon />
          </div>
          <div className="timelinelegend-section__item">
            <span>Minor</span>
            <LightAlertMinorIcon />
          </div>
          <div className="timelinelegend-section__item">
            <span>Major</span>
            <LightAlertMajorIcon />
          </div>
          <div className="timelinelegend-section__item">
            <span>Critical</span>
            <LightAlertCriticalIcon />
          </div>
        </div>
      </div>
    );
  }

  static getMarkerArray(isScheduleTimeline = false): { id: string, icon: string }[] {
    let markers: { id: string, icon: string }[] = [];

    if (isScheduleTimeline) {
      markers = [
        { id: 'markerScheduleFixed0', icon: MARKER_SCHEDULE_FIXED_0 },
        { id: 'markerScheduleFixed10', icon: MARKER_SCHEDULE_FIXED_10 },
        { id: 'markerScheduleFixed20', icon: MARKER_SCHEDULE_FIXED_20 },
        { id: 'markerScheduleFixed30', icon: MARKER_SCHEDULE_FIXED_30 },
        { id: 'markerScheduleFixed40', icon: MARKER_SCHEDULE_FIXED_40 },
        { id: 'markerScheduleFixed50', icon: MARKER_SCHEDULE_FIXED_50 },
        { id: 'markerScheduleFixed60', icon: MARKER_SCHEDULE_FIXED_60 },
        { id: 'markerScheduleFixed70', icon: MARKER_SCHEDULE_FIXED_70 },
        { id: 'markerScheduleFixed80', icon: MARKER_SCHEDULE_FIXED_80 },
        { id: 'markerScheduleFixed90', icon: MARKER_SCHEDULE_FIXED_90 },
        { id: 'markerScheduleFixed100', icon: MARKER_SCHEDULE_FIXED_100 },
        { id: 'markerSchedulePhotocell0', icon: MARKER_SCHEDULE_PHOTOCELL_0 },
        { id: 'markerSchedulePhotocell10', icon: MARKER_SCHEDULE_PHOTOCELL_10 },
        { id: 'markerSchedulePhotocell20', icon: MARKER_SCHEDULE_PHOTOCELL_20 },
        { id: 'markerSchedulePhotocell30', icon: MARKER_SCHEDULE_PHOTOCELL_30 },
        { id: 'markerSchedulePhotocell40', icon: MARKER_SCHEDULE_PHOTOCELL_40 },
        { id: 'markerSchedulePhotocell50', icon: MARKER_SCHEDULE_PHOTOCELL_50 },
        { id: 'markerSchedulePhotocell60', icon: MARKER_SCHEDULE_PHOTOCELL_60 },
        { id: 'markerSchedulePhotocell70', icon: MARKER_SCHEDULE_PHOTOCELL_70 },
        { id: 'markerSchedulePhotocell80', icon: MARKER_SCHEDULE_PHOTOCELL_80 },
        { id: 'markerSchedulePhotocell90', icon: MARKER_SCHEDULE_PHOTOCELL_90 },
        { id: 'markerSchedulePhotocell100', icon: MARKER_SCHEDULE_PHOTOCELL_100 },
      ];
    } else {
      markers = [
        { id: 'markerNeutral', icon: MARKER_NEUTRAL },
        { id: 'markerPin', icon: MARKER_PIN },
        { id: 'markerClear', icon: MARKER_GREEN },
        { id: 'markerClearSelected', icon: MARKER_GREEN_SELECTED },
        { id: 'markerMinor', icon: MARKER_CYAN },
        { id: 'markerMinorSelected', icon: MARKER_CYAN_SELECTED },
        { id: 'markerMajor', icon: MARKER_YELLOW },
        { id: 'markerMajorSelected', icon: MARKER_YELLOW_SELECTED },
        { id: 'markerCritical', icon: MARKER_ORANGE },
        { id: 'markerCriticalSelected', icon: MARKER_ORANGE_SELECTED },
      ];
    }

    return markers;
  }

  static initPixiZoomEvents(map: React.MutableRefObject<L.Map | undefined>, pixiOverlay: React.MutableRefObject<any>): void {
    const ticker = new PIXI.Ticker();
    ticker.add((delta) => {
      pixiOverlay.current.redraw({ type: 'redraw', delta });
    });

    map.current?.on('zoomstart', () => {
      ticker.start();
    });

    map.current?.on('zoomend', () => {
      ticker.stop();
    });

    map.current?.on('zoomanim', pixiOverlay.current.redraw, pixiOverlay.current);
  }

  static loadMarkerSprites(
    loader: React.MutableRefObject<PIXI.Loader>,
    markers: { id: string; icon: string; }[],
    resourcesLoaded: boolean,
    setResourcesLoaded: React.Dispatch<React.SetStateAction<boolean>>,
  ): void {
    let loadingAny = false;

    markers.forEach((marker) => {
      if (!loader.current.resources[marker.id]) {
        loadingAny = true;

        loader.current.add(marker.id, marker.icon);
      }
    });

    if (resourcesLoaded && loadingAny) {
      setResourcesLoaded(false);
    }

    if (loadingAny) {
      loader.current.load(() => setResourcesLoaded(true));
    } else {
      setResourcesLoaded(true);
    }
  }

  static initScheduleTimelineControl(mapInstance: L.Map): void {
    const scheduleTimelineLegendContainer = new L.Control({ position: 'bottomright' });
    scheduleTimelineLegendContainer.onAdd = () => {
      const container = L.DomUtil.create('div', 'leaflet-timelinelegend-container');
      ReactDOM.render(MapUtils.generateScheduleLegend(), container);

      return container;
    };

    const scheduleTimelineLegendButton = new L.Control.Button({
      position: 'bottomright',
      className: 'leaflet-control-timelinelegend',
      onMouseover: () => {
        scheduleTimelineLegendContainer.addTo(mapInstance);
      },
      onMouseout: () => {
        scheduleTimelineLegendContainer.remove();
      },
    });

    mapInstance.addControl(scheduleTimelineLegendButton as unknown as L.Control);
  }

  static initAlertsControl(mapInstance: L.Map): void {
    const alertsLegendContainer = new L.Control({ position: 'bottomright' });
    alertsLegendContainer.onAdd = () => {
      const container = L.DomUtil.create('div', 'leaflet-timelinelegend-container leaflet-timelinelegend-container--alerts-legend');
      ReactDOM.render(MapUtils.generateAlertsLegend(), container);
      return container;
    };

    const alertLegendButton = new L.Control.Button({
      position: 'bottomright',
      className: 'leaflet-control-timelinelegend--alerts-legend leaflet-control-timelinelegend',
      onMouseover: () => {
        alertsLegendContainer.addTo(mapInstance);
      },
      onMouseout: () => {
        alertsLegendContainer.remove();
      },
    });

    mapInstance.addControl(alertLegendButton as unknown as L.Control);
  }

  static initSelectionToolControl(
    mapInstance: L.Map,
    markerSprites: React.MutableRefObject<PIXI.Sprite[]>,
    popupDisabled: React.MutableRefObject<boolean>,
    popupDisabledManually: React.MutableRefObject<boolean>,
    setOpenedPopupData: React.Dispatch<React.SetStateAction<MapPopupData | undefined>>,
    multiSelectToolOptions: React.MutableRefObject<MultiSelectToolOptions>,
    setSelectedItems: React.Dispatch<React.SetStateAction<Map<string, any>>> | undefined,
    onlyPopupBtn?: boolean,
  ): void {
    const iconSize = [8, 8] as L.PointExpression;
    const drawPolygon = new L.Draw.Polygon(mapInstance as L.DrawMap, {
      icon: L.divIcon({
        iconSize,
        html: '<div class="leaflet-draw-poly"></div>',
      }),
      guidelineDistance: 30,
      allowIntersection: false,
      drawError: {
        color: '#E00',
        message: '<strong>One side cannot intersect another.</strong>',
      },
      shapeOptions: {
        color: '#FF47F6',
        weight: 1,
        opacity: 1,
        fill: false,
      },
    } as L.DrawOptions.PolygonOptions);

    const polygonButton = new L.Control.Button({
      className: 'leaflet-control-polygondraw',
      onClick: () => {
        drawPolygon.enable();
        multiSelectButton.disable();
        popupDisabled.current = true;
        setOpenedPopupData(undefined);
      },
      offClick: () => {
        drawPolygon.disable();
        popupDisabled.current = false;
        multiSelectButton.enable();
      },
      tooltipText1: 'Polygon tool',
      tooltipText2: 'Polygon tool',
    });

    const multiSelectToolContainer = new L.Control({ position: 'topright' });
    multiSelectToolContainer.onAdd = () => {
      const container = L.DomUtil.create('div', 'leaflet-multiselect-container');
      ReactDOM.render(
        <MultiSelectToolContainer
          container={multiSelectToolContainer}
          mouseAction={multiSelectToolOptions.current.mouseAction}
          setMouseAction={(newVal: MultiSelectToolOptions['mouseAction']) => {
            multiSelectToolOptions.current.mouseAction = newVal;
          }}
          selectionType={multiSelectToolOptions.current.selectionType}
          setSelectionType={(newVal: MultiSelectToolOptions['selectionType']) => {
            multiSelectToolOptions.current.selectionType = newVal;
          }}
        />,
        container,
      );

      return container;
    };

    const multiSelectButton = new L.Control.Button({
      className: 'leaflet-control-multiselect',
      onClick: () => {
        multiSelectToolOptions.current.enabled = true;
        multiSelectToolContainer.addTo(mapInstance);
        polygonButton.disable();
        popupDisabled.current = true;
        setOpenedPopupData(undefined);
      },
      offClick: () => {
        multiSelectToolOptions.current.enabled = false;
        multiSelectToolContainer.remove();
        polygonButton.enable();
        popupDisabled.current = false;
      },
      tooltipText2: 'Multi select tool',
    });

    const popupToggleButton = new L.Control.Button({
      className: 'leaflet-control-popup',
      toggledClassName: 'leaflet-control-popup-off',
      onClick: () => {
        popupDisabledManually.current = true;
        setOpenedPopupData(undefined);
      },
      offClick: () => {
        popupDisabledManually.current = false;
      },
      tooltipText1: 'Disable node pop-up',
      tooltipText2: 'Enable node pop-up',
    });

    if (!onlyPopupBtn) {
      mapInstance.addControl(polygonButton as unknown as L.Control);
      mapInstance.addControl(multiSelectButton as unknown as L.Control);
    }
    mapInstance.addControl(popupToggleButton as unknown as L.Control);

    mapInstance.on(L.Draw.Event.DRAWSTOP, () => {
      polygonButton.toggle(false);
      multiSelectButton.enable();
    });

    mapInstance.on(L.Draw.Event.CREATED, (e) => {
      const polygon = e.layer;
      const selectedNodes: Map<string, NodeObject> = new Map();

      // get vertices and remove duplicates
      const vertices = polygon.getLatLngs()[0];

      // find outer bounds
      let latmin = 90;
      let latmax = -90;
      let lngmin = 180;
      let lngmax = -180;

      for (let i = 0; i < vertices.length; i += 1) {
        if (vertices[i].lat < latmin) latmin = vertices[i].lat;
        if (vertices[i].lng < lngmin) lngmin = vertices[i].lng;
        if (vertices[i].lat > latmax) latmax = vertices[i].lat;
        if (vertices[i].lng > lngmax) lngmax = vertices[i].lng;
      }

      for (let j = 0; j < markerSprites.current.length; j += 1) {
        if (markerSprites.current[j].visible) {
          const lat = parseFloat(markerSprites.current[j].nodeData.latitude);
          const lng = parseFloat(markerSprites.current[j].nodeData.longitude);

          if (lat > latmin && lat < latmax && lng > lngmin && lng < lngmax) {
            if (Utils.verticesContainsPoint({ lat, lng }, vertices)) {
              markerSprites.current[j].selected = true;
            }
          }
        }

        if (markerSprites.current[j].selected) {
          selectedNodes.set(markerSprites.current[j].nodeData.nodeid, markerSprites.current[j].nodeData);
        }
      }

      if (setSelectedItems) {
        setSelectedItems(selectedNodes);
      }
    });
  }

  static mapSelectedItemsChange(
    mapEvent: Event,
    utils: any,
    loader: React.MutableRefObject<PIXI.Loader>,
    markerSprites: React.MutableRefObject<PIXI.Sprite[]>,
    nodeIdToMarkerIndex: React.MutableRefObject<Record<string, number>>,
  ): void {
    (mapEvent as CustomEvent).detail.added.forEach((nodeId: string) => {
      const markerIndex = nodeIdToMarkerIndex.current[nodeId];
      if (markerIndex !== undefined) {
        const currentTexture = markerSprites.current[markerIndex].textureKey;
        const newTexture = loader.current.resources[`${currentTexture}Selected`].texture;
        markerSprites.current[markerIndex].texture = newTexture;
        markerSprites.current[markerIndex].selected = true;
      }
    });

    (mapEvent as CustomEvent).detail.removed.forEach((nodeId: string) => {
      const markerIndex = nodeIdToMarkerIndex.current[nodeId];
      if (markerIndex !== undefined) {
        const currentTexture = markerSprites.current[markerIndex].textureKey;
        const newTexture = loader.current.resources[currentTexture.replace('Selected', '')].texture;
        markerSprites.current[markerIndex].texture = newTexture;
        markerSprites.current[markerIndex].selected = false;
      }
    });

    utils.getRenderer().render(utils.getContainer());
  }

  static scheduleAnimationRedraw(
    event: PIXI.InteractionEvent,
    loader: React.MutableRefObject<PIXI.Loader>,
    markerSprites: React.MutableRefObject<PIXI.Sprite[]>,
    nodeIdToMarkerIndex: React.MutableRefObject<Record<string, number>>,
    scheduleTimelines: ScheduleTimelineObject,
  ): void {
    const currTime = event.timelineTime || 0;
    const roundTo = 10;

    for (let i = 0; i < markerSprites.current.length; i += 1) {
      const nodeTimeline = scheduleTimelines.get(markerSprites.current[i].nodeData.lightinggroupid);
      if (nodeTimeline) {
        const markerIndex = nodeIdToMarkerIndex.current[markerSprites.current[i].nodeData.nodeid];
        const currentTextureKey = markerSprites.current[markerIndex].textureKey;
        const timelinePoint = nodeTimeline[currTime];
        const levelRoundedTo10 = Math.round(timelinePoint.level / roundTo) * roundTo;
        const newTextureKey = `markerSchedule${timelinePoint.photocell ? 'Photocell' : 'Fixed'}${levelRoundedTo10}`;

        if (currentTextureKey !== newTextureKey) {
          markerSprites.current[i].texture = loader.current.resources[newTextureKey].texture;
          markerSprites.current[i].textureKey = newTextureKey;
        }
      }
    }
  }

  static drawMarkers(
    utils: any,
    loader: React.MutableRefObject<PIXI.Loader>,
    container: any,
    data: any,
    center: { lat: number; lng: number; },
    markerSprites: React.MutableRefObject<PIXI.Sprite[]>,
    nodeIdToMarkerIndex: React.MutableRefObject<Record<string, number>>,
    nodeIdsWithInvalidCoord: React.MutableRefObject<string[]>,
    bounds: React.MutableRefObject<L.LatLngBounds | undefined>,
    scheduleTimelineEnabled: boolean | undefined,
    scheduleTimelines: ScheduleTimelineObject | undefined,
  ): void {
    markerSprites.current = [];
    nodeIdToMarkerIndex.current = {};
    nodeIdsWithInvalidCoord.current = [];
    const project = utils.latLngToLayerPoint;
    const scale = utils.getScale();
    const invScale = 1 / scale;
    const siteCenter = center as LatLng;
    const roundTo = 10;
    const anchorCoord = 0.5;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data.length; i++) {
      const node = { ...data[i] };
      let textureKey = '';

      if (Utils.invalidLatLng(node)) {
        nodeIdsWithInvalidCoord.current.push(node.nodeid);

        if (typeof node.latitudeGps !== 'undefined'
            && node.latitudeGps !== ''
            && Utils.isNumeric(node.latitudeGps)) {
          node.latitude = parseFloat(node.latitudeGps);
          node.longitude = parseFloat(node.longitudeGps);
        } else {
          node.latitude = siteCenter.lat;
          node.longitude = siteCenter.lng;
        }
      }

      if (scheduleTimelineEnabled && scheduleTimelines) {
        const timeline = scheduleTimelines.get(node.lightinggroupid);
        const timelinePoint = timeline && timeline[0] ? timeline[0] : { photocell: true, level: 0 };
        const levelRoundedTo10 = Math.round(timelinePoint.level / roundTo) * roundTo;

        textureKey = `markerSchedule${timelinePoint.photocell ? 'Photocell' : 'Fixed'}${levelRoundedTo10}`;
      } else {
        textureKey = `marker${node.mapStatus}${node.mapSelected ? 'Selected' : ''}`;
      }

      if (textureKey in loader.current.resources) {
        const markerTexture = loader.current.resources[textureKey].texture;
        const markerSprite = PIXI.Sprite.from(markerTexture);

        const markerCoords = project([node.latitude, node.longitude]);
        markerSprite.anchor.set(anchorCoord, anchorCoord);
        markerSprite.x = markerCoords.x;
        markerSprite.y = markerCoords.y;
        markerSprite.textureKey = textureKey;
        markerSprite.scale.set(invScale);
        markerSprite.nodeData = node;
        markerSprite.selected = false;
        markerSprite.interactive = true;
        markerSprite.cursor = 'pointer';
        markerSprite.buttonMode = true;

        if (!bounds.current) {
          bounds.current = L.latLngBounds([{
            lat: node.latitude,
            lng: node.longitude,
          }]);
        } else {
          bounds.current.extend({ lat: node.latitude, lng: node.longitude });
        }

        nodeIdToMarkerIndex.current[node.nodeid] = i;
        markerSprites.current.push(markerSprite);

        container.addChild(markerSprite);
      }
    }
  }

  static drawPin(
    utils: any,
    loader: React.MutableRefObject<PIXI.Loader>,
    container: any,
    markerPin: React.MutableRefObject<PIXI.Sprite | undefined>,
    node: NodeObject,
    center: { lat: number; lng: number; },
  ): void {
    const project = utils.latLngToLayerPoint;
    const scale = utils.getScale();
    const invScale = 1 / scale;
    const siteCenter = center as LatLng;

    const markerTexture = loader.current.resources.markerPin.texture;
    const markerSprite = PIXI.Sprite.from(markerTexture);

    if (Utils.invalidLatLng(node)) {
      if (typeof node.latitudeGps !== 'undefined'
          && node.latitudeGps !== ''
          && Utils.isNumeric(node.latitudeGps)) {
        node.latitude = node.latitudeGps;
        node.longitude = node.longitudeGps;
      } else {
        node.latitude = siteCenter.lat.toString();
        node.longitude = siteCenter.lng.toString();
      }
    }

    const markerCoords = project([node.latitude, node.longitude]);
    markerSprite.anchor.set(0.5, 1.2);
    markerSprite.x = markerCoords.x;
    markerSprite.y = markerCoords.y;
    markerSprite.scale.set(invScale);

    markerPin.current = markerSprite;

    container.addChild(markerSprite);
  }

  static centerMapRef(
    map: React.MutableRefObject<L.Map | undefined>,
    bounds: React.MutableRefObject<L.LatLngBounds | undefined>,
    center: { lat: number; lng: number; },
    isCentered: React.MutableRefObject<boolean>,
  ): void {
    if (!bounds.current) {
      bounds.current = L.latLngBounds([center]);
    }

    if (!isCentered.current) {
      isCentered.current = true;
      map.current?.fitBounds(bounds.current as LatLngBoundsExpression, {
        maxZoom: 17,
        padding: [50, 50],
      });
    }
  }

  static openPopup(map: L.Map, data: MapPopupData, extraOptions = {}) {
    const popup = L.popup({ offset: data.offset as L.PointTuple, ...extraOptions })
      .setLatLng(data.position as L.LatLngExpression)
      .setContent(data.content)
      .openOn(map);

    popup.on('remove', () => {
      if (data.onClick) {
        data.onClick(null);
      }
    });

    return popup;
  }

  static gpsPinMouseEventHandler(e: LeafletMouseEvent, setGpsPinPosition: React.Dispatch<React.SetStateAction<{ lat: string; lng: string; } | undefined>> | undefined) {
    if (setGpsPinPosition) {
      setGpsPinPosition({ lat: e.latlng.lat.toFixed(6), lng: e.latlng.lng.toFixed(6) });
    }
  }

  static nodeMarkerMouseEventHandler(
    e: LeafletMouseEvent,
    pixiOverlay: React.MutableRefObject<any>,
    lastHitTarget: React.MutableRefObject<PIXI.Sprite | undefined>,
    multiSelectToolOptions: React.MutableRefObject<MultiSelectToolOptions>,
    selectionToolControlEnabled: boolean | undefined,
    popupDisabled: React.MutableRefObject<boolean>,
    popupDisabledManually: React.MutableRefObject<boolean>,
    setOpenedPopupData: React.Dispatch<React.SetStateAction<MapPopupData | undefined>>,
    selectedItems: Map<string, any> | undefined,
    setSelectedItems: React.Dispatch<React.SetStateAction<Map<string, any>>> | undefined,
    addNodeToSelected: (target: PIXI.Sprite) => void,
    removeNodeFromSelected: (target: PIXI.Sprite) => void,
  ): void {
    const { utils } = pixiOverlay.current;
    const { interaction } = utils.getRenderer().plugins;

    const pointerEvent = e.originalEvent;
    const pixiPoint = new PIXI.Point();

    interaction.mapPositionToPoint(pixiPoint, pointerEvent.clientX, pointerEvent.clientY);
    const target: PIXI.Sprite = interaction.hitTest(pixiPoint, utils.getContainer());
    const sameTarget = lastHitTarget.current === target;

    // show node data popup
    if (target && target.nodeData && !popupDisabled.current && !popupDisabledManually.current) {
      setOpenedPopupData({
        offset: [0, -2],
        position: [
          parseFloat(target.nodeData.latitude),
          parseFloat(target.nodeData.longitude),
        ],
        content: MapUtils.generatePopupContent(target.nodeData),
      });
    } else if (!sameTarget) {
      setOpenedPopupData(undefined);
    }

    // target hit
    if (target && target.nodeData && selectionToolControlEnabled && selectedItems && setSelectedItems) {
      // multiselect tool enabled
      if (multiSelectToolOptions.current.enabled) {
        const isClick = pointerEvent.type === 'click' && multiSelectToolOptions.current.mouseAction.key === 'click';
        const isHover = pointerEvent.type === 'mousemove' && multiSelectToolOptions.current.mouseAction.key === 'hover';

        if ((isClick || isHover) && !(isHover && sameTarget)) {
          let newNodeSelectedStatus = null as any;

          switch (multiSelectToolOptions.current.selectionType.key) {
            case 'toggle':
              newNodeSelectedStatus = !target.selected;
              break;
            case 'selectOnly':
              newNodeSelectedStatus = true;
              break;
            case 'deselectOnly':
              newNodeSelectedStatus = false;
              break;
            default:
              break;
          }

          if (newNodeSelectedStatus === true) {
            addNodeToSelected(target);
          } else if (newNodeSelectedStatus === false) {
            removeNodeFromSelected(target);
          }
        }
      } else if (pointerEvent.type === 'click') {
        // SHIFT key pressed while click
        if (pointerEvent.shiftKey) {
          if (target.selected) {
            // already selected, remove this one only
            removeNodeFromSelected(target);
          } else {
            // add this to selected
            addNodeToSelected(target);
          }
        } else if (target.selected) {
          // already selected, remove all selected
          setSelectedItems(new Map());
        } else {
          // set this as only selected
          setSelectedItems(new Map([[target.nodeData.nodeid, target.nodeData]]));
        }
      }
    }

    lastHitTarget.current = target;
  }

  static drawPolygon(
    map: L.Map,
    center: {lat: number; lng: number; },
    geoFencePolygon: React.MutableRefObject<L.Polygon<any> | undefined>,
    geoFencePolygonLayers: React.MutableRefObject<L.Layer[]>,
    geoFence: GeoFence,
    allSitesGeoJson: GeoJSON.MultiPolygon['coordinates'],
    setGeoFence?: (newGeoFence: GeoFence, geoFenceCenter: L.LatLng) => void,
    setMapError?: (errorMsg: string) => void,
  ): void {
    const isEditable = !!setGeoFence && !!setMapError;
    const bounds = L.latLngBounds([]);
    let foundCoord = false;

    if (!MapUtils.isValidGeoFence(geoFence)) {
      return;
    }

    const polygonColor = isEditable ? '#FF47F6' : '#3DF8FF';

    const polygon = new L.Polygon([], { color: polygonColor, weight: 2, fillOpacity: 0.1 });
    geoFencePolygon.current = polygon;

    geoFence.forEach((latlng) => {
      const lat = parseFloat(latlng.latitude);
      const lng = parseFloat(latlng.longitude);

      polygon.addLatLng({ lat, lng });
      bounds.extend({ lat, lng });
      foundCoord = true;
    });

    if (isEditable) {
      if (allSitesGeoJson.length && allSitesGeoJson[0] && allSitesGeoJson[0].length) {
        const multiPolygon = L.geoJSON<GeoJSON.MultiPolygon>(
          { type: 'MultiPolygon', coordinates: allSitesGeoJson } as GeoJSON.MultiPolygon,
          { style: () => ({ color: '#3DF8FF', weight: 2, fillOpacity: 0.1 }) },
        );

        geoFencePolygonLayers.current.push(multiPolygon);
        map.addLayer(multiPolygon);

        const polyGeoJsonCoords: turf.Position[][] = polygon.toGeoJSON().geometry.coordinates as turf.Position[][];
        MapUtils.isPolygonIntersect(polygon, polyGeoJsonCoords, allSitesGeoJson, setMapError);
      }

      polygon.editing.enable();

      // finished editing
      map.on(L.Draw.Event.EDITVERTEX, (event: L.LeafletEvent) => MapUtils.polygonEditHandler(event, allSitesGeoJson, setGeoFence, setMapError));
    }

    map.addLayer(polygon);

    if (!foundCoord) {
      bounds.extend(center);
    }

    map.fitBounds(bounds as LatLngBoundsExpression, {
      maxZoom: 17,
      padding: [100, 100],
    });
  }

  static polygonEditHandler(
    event: L.LeafletEvent,
    allSitesGeoJson: GeoJSON.MultiPolygon['coordinates'],
    setGeoFence?: (newGeoFence: GeoFence, geoFenceCenter: L.LatLng) => void,
    setMapError?: (errorMsg: string) => void,
  ): void {
    const polyEdited: L.Polygon = event.poly;
    const polyGeoJsonCoords: turf.Position[][] = polyEdited.toGeoJSON().geometry.coordinates as turf.Position[][];

    if (allSitesGeoJson.length && allSitesGeoJson[0] && allSitesGeoJson[0].length) {
      if (MapUtils.isPolygonIntersect(polyEdited, polyGeoJsonCoords, allSitesGeoJson, setMapError)) {
        return;
      }
    }
    if (setGeoFence) {
      const polyCoords = polyGeoJsonCoords.flat(1);
      polyCoords.pop();
      const newGeoFence = polyCoords.map((latlng: turf.Position) => ({ latitude: latlng[1].toFixed(6), longitude: latlng[0].toFixed(6) }));

      const newCenterRaw: number[] = polylabel(polyGeoJsonCoords);

      if (newCenterRaw && newCenterRaw[0] && newCenterRaw[1]) {
        const newCenter = L.latLng(newCenterRaw[1], newCenterRaw[0]);

        setGeoFence(newGeoFence, newCenter);
      }
    }
  }

  static isPolygonIntersect(
    polygon: L.Polygon,
    polyGeoJsonCoords: turf.Position[][],
    allSitesGeoJson: GeoJSON.MultiPolygon['coordinates'],
    setMapError?: (errorMsg: string) => void,
  ) {
    const turfPoly = turf.polygon(polyGeoJsonCoords);
    MapUtils.clearPolygonError(polygon, setMapError);

    // self intersection
    if (kinks(turfPoly).features.length > 0) {
      this.setPolygonError(polygon, 'Site geo-fence cannot self-intersect.', 235, setMapError);
      return true;
    }

    // itersection with other sites
    const allSitesTurfPoly = turf.multiPolygon(allSitesGeoJson);
    if (intersect(turfPoly, allSitesTurfPoly)) {
      this.setPolygonError(polygon, 'Site geo-fence intersects with another site.', 270, setMapError);
      return true;
    }

    return false;
  }

  static setPolygonError(polygon: L.Polygon, errorMsg: string, width: number, setMapError?: (msg: string) => void) {
    polygon.setStyle({ color: '#ED7000' });

    if (setMapError) {
      setMapError(errorMsg);
    }
  }

  static clearPolygonError(polygon: L.Polygon, setMapError?: (errorMsg: string) => void) {
    polygon.setStyle({ color: '#FF47F6' });
    polygon.closePopup().unbindPopup();

    if (setMapError) {
      setMapError('');
    }
  }

  static isValidSearchLocation(search: MapSearchResult): boolean {
    return (
      !!search.position
      && !!search.position.lat
      && !!search.position.lng
    );
  }

  static isValidGeoFence(geofence: GeoFence) {
    return (
      geofence
      && geofence.length > 2
      && geofence.every((latlng) => latlng.latitude && latlng.longitude)
    );
  }

  static getDistance(from: number[], to: number[]): number {
    return distance(turf.point(from), turf.point(to), { units: 'miles' });
  }

  static initSearch(map: L.Map, mapSearchRef: React.RefObject<HTMLDivElement>, setSearchResult: (searchResult: MapSearchResult) => void): void {
    const provider = new HereProvider({
      params: {
        apiKey: here.apiKey,
        // rank suggestions according to distance from geographic center of the US
        at: '39.8090803,-98.5599219',
      },
    });
    const searchControl = GeoSearchControl({
      animateZoom: true,
      autoClose: false,
      autoComplete: true,
      autoCompleteDelay: 250,
      keepResult: false,
      maxSuggestions: 5,
      messageHideDelay: 3000,
      position: 'topleft',
      retainZoomLevel: false,
      style: 'bar',
      notFoundMessage: 'Sorry, that address could not be found.',
      resultFormat: ({ result }) => result.label,
      popupFormat: ({ result }) => result.label,
      classNames: {
        container: 'leaflet-bar leaflet-control leaflet-control-geosearch',
        button: 'leaflet-bar-part leaflet-bar-part-single',
        resetButton: 'reset',
        msgbox: 'leaflet-bar message',
        form: '',
        input: '',
        resultlist: '',
        item: '',
        notfound: 'leaflet-bar-notfound',
      },
      marker: {
        icon: {
          options: {},
          _initHooksCalled: true,
        },
        draggable: false,
      },
      maxMarkers: 1,
      provider,
      searchLabel: 'Enter site address',
      showMarker: false,
      showPopup: false,
      updateMap: true,
      zoomLevel: 18,
      container: mapSearchRef.current,
    });

    map.addControl(searchControl);

    map.on('geosearch/showlocation', (e: any) => setSearchResult(e.location.raw));
  }
}

export default MapUtils;
