/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DateTime, DurationInput } from 'luxon';
import React from 'react';
import suncalc from 'suncalc';
import { DraggedDimmingPoint } from '../types/DimmingPointsProps';
import { EnergyRecord, FilledEnergyRecord } from '../types/EnergyGraphProps';
import { EnergyQueryPayload } from '../types/EnergyQueryPayload';
import { FirmwareObject } from '../types/FirmwareObjects';
import { ChartBase } from '../types/GroupsSelector';
import { NodeObject } from '../types/NodeObject';
import { SelectedSite } from '../types/PageComponentProps';
import { ReportDefinitionObject } from '../types/ReportDefinitionObject';
import { RadioButtonElementProps } from '../types/RadioButtonsProps';
import { ScheduleEventActionPoint, SunriseSunsetObject } from '../types/ScheduleObject';
import { SelectBoxItemType } from '../types/SelectBoxPropsType';
import { SiteObject } from '../types/SiteObject';
import { TableHeadersProp } from '../types/TableHeadersProp';
import CanvasUtils from './CanvasUtils';
import { BASEPATH, defaultReportDefinition, chartTypes, reportModes, modelNames, modelSubTypes, modelTypes, reportColumns, reportGranularityOptions, fixSunrise, fixSunset, reportTypeTitles } from './constants';
import { TableFiltering, TableSorting } from '../types/table';
import { AnalysisDashboardQueryPayload } from '../types/AnalysisDashboardQueryPayload';

class Utils {
  static csrfTokenName = 'netsense-csrf-token';

  static JSONWebTokenName = 'authorization';

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static missingAPIdata = (val: any): any => val;

  static getCookie(name: string): string {
    const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
    return v ? v[2] : '';
  }

  static setCookie(name: string, value: string, expirySeconds: number): void {
    const now = new Date();
    now.setTime(now.getTime() + expirySeconds * 1000);
    document.cookie = `${name}=${value};path=/;SameSite=Lax;expires=${now.toUTCString()}`;
  }

  static getConvertedDate(date: Date | string | undefined, targetFormat = 'h:mm a ZZZZ LL/dd/yyyy', timezone = 'local'): string {
    if (date === undefined || date === '') {
      return '';
    }

    return DateTime.fromJSDate((new Date(date)), { zone: timezone }).toFormat(targetFormat);
  }

  static getConvertedValue(sensorid: string, value: string): string {
    const secondsInHour = 3600;
    const decimalPrecison = 2;

    if (sensorid === 'BHL' || sensorid === 'bhl') {
      const result = parseFloat(value) / secondsInHour;
      return result.toFixed(decimalPrecison).toString();
    }
    if (sensorid === 'T') {
      const result = (parseFloat(value) * (9 / 5)) + 32;
      return result.toFixed(decimalPrecison).toString();
    }
    if (sensorid === 'mw') {
      const result = (parseFloat(value) / 1000);
      return result.toFixed(decimalPrecison).toString();
    }
    if (sensorid === 'sinr') {
      const result = (parseFloat(value) * 2 - 200) / 10;
      return (Math.round(result * 10) / 10).toFixed(decimalPrecison).toString();
    }
    return parseFloat(value).toFixed(decimalPrecison).toString();
  }

  static getSiteConvertedDate(date: Date | string | undefined, targetFormat: string, timezone = 'America/New_York'): string {
    if (date === undefined || date === '') {
      return '';
    }

    return DateTime.fromJSDate((new Date(date)), { zone: timezone }).toFormat(targetFormat);
  }

  static getSensorConvertedDate(date: string | undefined, timezone: string): string {
    if (date === undefined || date === '' || date === '--') {
      return '--';
    }

    let datetime: DateTime;

    if (date.includes('T')) {
      datetime = DateTime.fromISO(date);
    } else {
      datetime = DateTime.fromSQL(date, { zone: 'UTC' });
    }

    return datetime.setZone(timezone).toFormat('h:mm a ZZZZ LL/dd/yyyy');
  }

  static convertISOtoJobTime(iso: string | null, timezone: string) : string {
    if (iso === null || typeof iso === 'undefined' || iso.length === 0) {
      return '---';
    }

    const dt = DateTime.fromISO(iso, { zone: timezone });
    return dt.toFormat('MM/dd/yyyy   h:mma ') + dt.offsetNameShort;
  }

  static convertISOtoAlarmTime(iso: string | null, timezone: string | undefined) : string {
    if (iso === null || typeof iso === 'undefined' || iso.length === 0) {
      return '---';
    }

    if (timezone === undefined) {
      timezone = 'America/New_York';
    }

    const dt = DateTime.fromISO(iso).setZone(timezone);

    if (dt.invalidReason) {
      return '---';
    }

    return (dt.toFormat('hh:mma ') + dt.offsetNameShort + dt.toFormat(' MM/dd/yyyy'));
  }

  static convertISOtoAlarmTime2(iso: string | null, timezone: string | undefined) : string {
    if (iso === null || typeof iso === 'undefined' || iso.length === 0) {
      return '---';
    }

    if (timezone === undefined) {
      timezone = 'America/New_York';
    }

    const d = (new Date(iso)).toLocaleString('en-US', {
      timeZone: timezone,
      timeZoneName: 'short',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      year: 'numeric',
    }).split(' ');

    // eslint-disable-next-line prefer-template
    return (d[1] + d[2] + ' ' + d[3] + ' ' + d[0].slice(0, -1));
  }

  static getAccountName(orgId: string) : string {
    const info = Utils.getUserInfo().user;
    return info.orgNames[info.orgs.findIndex((o) => o === orgId)] || '---';
  }

  static getSiteName(siteId: string) : string {
    const info = Utils.getUserInfo().user;
    return info.siteNames[info.sites.findIndex((s) => s === siteId)] || '---';
  }

  static formatReportDetails(report) {
    const item = report;
    item.reportAccount = Utils.getAccountName(item.orgId);
    item.reportSite = Utils.getSiteName(item.siteId);
    item.reportTypeTitle = reportTypeTitles[item.reportType];
    item.reportScheduleType = {
      CANNED: 'Auto generated' }[item.reportScheduleType] || item.reportScheduleType;
    item.reportStatus = {
      CREATED: 'Created',
      SCHEDULED: 'Scheduled',
      FAILED: 'Failed',
      SENT: 'Sent',
      GENERATED: 'Ready' }[item.reportStatus] || '---';
    return item;
  }

  static defaultReportDefinition(processor: string): ReportDefinitionObject {
    const def = defaultReportDefinition;
    def.startDate = (new Date()).toISOString();
    def.endDate = new Date(Date.now() + 31 * 24 * 60 * 60 * 1000).toISOString();
    if (processor === 'ADVANCED_ENERGY') {
      def.selectedResolution = null;
    }
    return def;
  }

  static arrayToSelectbox(
    array: Array<any>,
    keyProp: string,
    titleProp: string,
  ): Array<SelectBoxItemType> {
    const selectboxArray: Array<SelectBoxItemType> = [];

    array?.forEach((val: any) => {
      selectboxArray.push({
        key: val[keyProp],
        title: val[titleProp],
      });
    });

    return selectboxArray;
  }

  static valueToRadioButtonItem(value: string, items: Array<RadioButtonElementProps>): RadioButtonElementProps {
    return { label: value, key: items.findIndex((itm) => itm.label === value).toString(10) };
  }

  static valueToSelectBoxItem(value: string, items: Array<SelectBoxItemType>): SelectBoxItemType {
    return { title: value, key: items.findIndex((itm) => itm.title === value).toString(10) };
  }

  static decodeSemanticCompressed(
    items: Array<any>,
    map: Record<string, string>,
  ): Array<any> {
    const result: Array<any> = [];

    if (items.length > 0) {
      const keys = Object.keys(map);

      items.forEach((item: any, i: number) => {
        // eslint-disable-next-line dot-notation
        if (item['E'] && (item['E'] === 'null' || item['E'] === '')) {
          // eslint-disable-next-line dot-notation
          item['E'] = '["Site Lighting Group"]';
        }
        result[i] = i === 0 ? { ...items[0] } : { ...result[i - 1] };

        keys.forEach((key: string) => {
          if (item[map[key]] !== undefined) {
            result[i][key] = item[map[key]];
          }
        });

        if (i === 0) {
          keys.forEach((key: string) => {
            delete result[0][map[key]];
          });
        }
      });
    }

    return result;
  }

  static async logout(): Promise<any> {
    const url = `${window.NSN.loginappURL}/logout?client_id=${
      window.NSN.loginappClientId || ''
    }&logout_uri=${window.location.origin}${BASEPATH}`;
    const response = await fetch(url, { credentials: 'include' });

    localStorage.removeItem('netsense-csrf-token');
    sessionStorage.clear();

    const data: any = await response.json();

    if (data.redirect) {
      window.location.href = data.redirect;
    } else {
      window.location.href = `${BASEPATH}/login`;
    }
  }

  static getModelName(model: string): string {
    return modelNames[model] ? modelNames[model] : model;
  }

  static getModelType(model: string): string {
    return modelTypes[model] ? modelTypes[model] : 'Unknown';
  }

  static getModelSubType(model: string): string {
    return modelSubTypes[model] ? modelSubTypes[model] : 'N';
  }

  static getChartTypeString(type: string): string {
    return chartTypes[type] ? chartTypes[type] : 'N';
  }

  static getModeString(type: string): string {
    return reportModes[type] ? reportModes[type] : 'N';
  }

  static getColumnNameString(type: string): string {
    return reportColumns[type] ? reportColumns[type] : 'N';
  }

  static getGranularityOptionString(type: string): string {
    return reportGranularityOptions[type] ? reportGranularityOptions[type] : 'N';
  }

  static nodeStatusOk(node: NodeObject): boolean {
    return node.mapStatus === 'Clear';
  }

  static getSiteLatLng(site: SiteObject | undefined): { lat: number, lng: number } {
    return {
      lat: parseFloat(site?.latitude || '37.3807927'),
      lng: parseFloat(site?.longitude || '-121.9928375'),
    };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static isNumeric(n: any): boolean {
    const num = parseFloat(n);
    return !Number.isNaN(num) && Number.isFinite(num);
  }

  static invalidLatLng(node: { [x: string]: any }): boolean {
    return (
      typeof node.latitude === 'undefined'
    || node.latitude === ''
    || !Utils.isNumeric(node.latitude)
    || parseFloat(node.latitude) < -90
    || parseFloat(node.latitude) > 90
    || typeof node.longitude === 'undefined'
    || node.longitude === ''
    || !Utils.isNumeric(node.longitude)
    || parseFloat(node.longitude) < -180
    || parseFloat(node.longitude) > 180
    );
  }

  static verticesContainsPoint(point: { lat: number, lng: number}, vs: Array<{ lat: number, lng: number}>): boolean {
    // ray-casting algorithm based on
    // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

    const x = point.lat;
    const y = point.lng;

    let inside = false;
    // eslint-disable-next-line no-plusplus
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      const xi = vs[i].lat;
      const yi = vs[i].lng;
      const xj = vs[j].lat;
      const yj = vs[j].lng;

      // eslint-disable-next-line no-mixed-operators
      const intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) {
        inside = !inside;
      }
    }

    return inside;
  }

  static downloadCSV(headers: Array<TableHeadersProp>, data: Array<Record<string, any>>, fileName: string, doAddDatetime = true): void {
    let csvContent = '';
    // eslint-disable-next-line dot-notation
    const usefullHeaders = headers
      .filter((item) => item.val !== undefined);
    const tableHeaders = usefullHeaders
      .map((header) => header.val)
      .join(', ');
    csvContent += `${tableHeaders}\r\n`;

    let rowCount = -1;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data.length; i++) {
      const tableRow: string[] = [];
      const item = data[i];

      usefullHeaders.forEach((headerObj) => {
        let rowElementVal = '';

        if (headerObj.key === 'mapStatus') {
          rowElementVal = item.mapStatus === 'Clear' ? 'Ok' : item.mapStatus;
        } else {
          rowElementVal = (item[headerObj.key] === 0 || item[headerObj.key]) ? item[headerObj.key].toString() : '';
        }

        tableRow.push(rowElementVal
          ? (rowElementVal.replaceAll(',', '.')).replaceAll('. ', ' ')
          : '');
      });

      const tableRowStr = tableRow.join(',');

      if (rowCount !== i) {
        csvContent += `${tableRowStr}\r\n`;
        rowCount = i;
      }
    }

    const newFileName = doAddDatetime ? `${fileName}_${Utils.getConvertedDate(new Date())}` : `${fileName}`;

    // eslint-disable-next-line prefer-template
    const encodedUri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${newFileName}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  static getFirmwareByNodeModelName(selectedNode: NodeObject, firmwareResp: FirmwareObject[] | undefined): FirmwareObject | undefined {
    if (!selectedNode.softwareVersion) {
      return undefined;
    }

    const firmwareToVersion = firmwareResp?.find((firmware) => {
      // version or hash (lco)
      const versionArr = selectedNode.softwareVersion?.split('-').filter((s) => (s.includes('.') && s.length === 5) || s.length === 7);

      if (!versionArr || versionArr.length === 0) {
        return undefined;
      }

      return (
        firmware.displayName
          .includes(selectedNode.softwareVersion)
            || versionArr.every((val) => firmware.displayName.includes(val) && (firmware.firmwareId.includes(val) || firmware.version.includes(val)))
      );
    });

    return firmwareToVersion;
  }

  static parseScheduleActionTime(time: string, sunTimes: SunriseSunsetObject): number {
    if (time.indexOf(':') > 0) {
      const h = parseInt(time.substring(0, 2), 10);
      const m = parseInt(time.substring(3, 5), 10);

      return (h * 60 + m);
    }

    if (time.indexOf('sunrise') >= 0) {
      return (sunTimes.sunrise + (time.length > 7 ? parseInt(time.substring(7), 10) : 0));
    }

    return (sunTimes.sunset + (time.length > 6 ? parseInt(time.substring(6), 10) : 0));
  }

  static convertScheduleActionTime(minutes: number, fixedTime: boolean): string {
    if (fixedTime) {
      const h = Math.floor(minutes / 60);
      const m = minutes % 60;
      return `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:00`;
    }

    if (minutes <= 720) {
      // before sunrise
      if (minutes < fixSunrise) {
        return `sunrise-${fixSunrise - minutes}`;
      }
      // after sunrise
      if (minutes > fixSunrise) {
        return `sunrise+${minutes - fixSunrise}`;
      }
      // sunrise
      return 'sunrise';
    }

    // before sunset
    if (minutes < fixSunset) {
      return `sunset-${fixSunset - minutes}`;
    }
    // after sunset
    if (minutes > fixSunset) {
      return `sunset+${minutes - fixSunset}`;
    }
    // sunset
    return 'sunset';
  }

  static convertTimeForTooltip(time: string): string {
    if (time.includes('sun')) {
      return `${time.charAt(0).toUpperCase()}${time.slice(1)}`.replace('-', ' - ').replace('+', ' + ');
    }

    const hour = parseInt(time.substring(0, 2), 10);
    const h = hour % 12 || 12;
    const ampm = (hour < 12 || hour === 24) ? 'am' : 'pm';
    return `${h}:${time.substring(3, 5)} ${ampm}`;
  }

  static calcActionByCanvas(
    event: React.MouseEvent,
    draggedDimmingPoint: DraggedDimmingPoint,
    tmpDimmingPoints: ScheduleEventActionPoint[],
    canvasWidth: number,
    canvasHeight: number,
    canvasMargin: number,
  ): ScheduleEventActionPoint | undefined {
    const canvasXY = CanvasUtils.getCanvasXY(event, canvasMargin);

    if (canvasXY) {
      const { canvasX, canvasY } = canvasXY;

      const levelPercent = 100 - ((canvasY / (canvasHeight - canvasMargin)) * 100);
      const levelPercentNearest5 = Math.min(Math.max(Math.round(levelPercent / 5) * 5, 0), 100);
      const y = Math.ceil(((100 - levelPercentNearest5) / 100) * (canvasHeight - (2 * canvasMargin)));

      const timeMinutes = (canvasX / (canvasWidth - canvasMargin)) * 1440;
      const timeMinutesNearest5 = Math.min(Math.max(Math.round(timeMinutes / 5) * 5, 0), 1440);
      const time = Utils.convertScheduleActionTime(timeMinutesNearest5, draggedDimmingPoint?.type === 'fixed');
      const x = Math.ceil((timeMinutesNearest5 / 1440) * (canvasWidth - (2 * canvasMargin)));

      if (tmpDimmingPoints.filter((dimmingPoint) => dimmingPoint.x === x).length) {
        // can't set dimming point for the same minute as any other one
        return undefined;
      }

      return {
        action: {
          level: levelPercentNearest5,
          time,
          photocell_enabled: draggedDimmingPoint?.photocell === true,
        },
        x,
        y,
        dragged: true,
      };
    }

    return undefined;
  }

  static calcActionByKeypress(
    event: React.KeyboardEvent,
    originalActionPoint: ScheduleEventActionPoint,
    tmpDimmingPoints: ScheduleEventActionPoint[],
    canvasWidth: number,
    canvasHeight: number,
    canvasMargin: number,
  ): ScheduleEventActionPoint | undefined {
    if (!['ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft'].includes(event.key)) {
      return undefined;
    }

    const incr = 5; // 5min and 5% increments only
    const { level, time } = originalActionPoint.action;

    let newLevel = level;
    let newTime = Utils.parseScheduleActionTime(time, { sunrise: fixSunrise, sunset: fixSunset });

    if (event.key === 'ArrowUp') {
      newLevel += incr;
    } else if (event.key === 'ArrowDown') {
      newLevel -= incr;
    } else if (event.key === 'ArrowRight') {
      newTime += incr;
    } else if (event.key === 'ArrowLeft') {
      newTime -= incr;
    }

    const levelPercentNearest5 = Math.min(Math.max(Math.round(newLevel / 5) * 5, 0), 100);
    const y = Math.ceil(((100 - levelPercentNearest5) / 100) * (canvasHeight - (2 * canvasMargin)));

    let timeMinutesNearest5 = Math.min(Math.max(Math.round(newTime / 5) * 5, 0), 1440);
    let x = Math.ceil((timeMinutesNearest5 / 1440) * (canvasWidth - (2 * canvasMargin)));

    if (tmpDimmingPoints.filter((dimmingPoint) => dimmingPoint.x === x).length) {
      // can't set dimming point for the same minute as any other one
      if (event.key === 'ArrowRight') {
        newTime += incr;
      } else if (event.key === 'ArrowLeft') {
        newTime -= incr;
      }

      timeMinutesNearest5 = Math.min(Math.max(Math.round(newTime / 5) * 5, 0), 1440);
      x = Math.ceil((timeMinutesNearest5 / 1440) * (canvasWidth - (2 * canvasMargin)));
    }

    return {
      action: {
        level: levelPercentNearest5,
        time: Utils.convertScheduleActionTime(timeMinutesNearest5, !time.includes('sun')),
        photocell_enabled: originalActionPoint.action.photocell_enabled,
      },
      x,
      y,
      dragged: false,
    };
  }

  static getSunriseSunset(date: Date, latLng: { lat: number, lng: number }, timeZone: string | null): SunriseSunsetObject {
    let sunTimes = suncalc.getTimes(new Date(date), latLng.lat, latLng.lng);

    if (Number.isNaN(Date.parse(sunTimes.sunrise.toString()))
      || Number.isNaN(Date.parse(sunTimes.sunset.toString()))
    ) {
      const defaultLatLng = Utils.getSiteLatLng(undefined);
      sunTimes = suncalc.getTimes(new Date(date), defaultLatLng.lat, defaultLatLng.lng);
    }

    let sunrise;
    let sunset;

    if (timeZone) {
      const sunriseDtZoned = DateTime.fromISO(new Date(sunTimes.sunrise).toISOString(), { zone: timeZone });
      const sunsetDtZoned = DateTime.fromISO(new Date(sunTimes.sunset).toISOString(), { zone: timeZone });

      sunrise = {
        h: sunriseDtZoned.toFormat('HH'),
        m: sunriseDtZoned.toFormat('mm'),
      };
      sunset = {
        h: sunsetDtZoned.toFormat('HH'),
        m: sunsetDtZoned.toFormat('mm'),
      };
    } else {
      const sunriseDt = DateTime.fromISO(new Date(sunTimes.sunrise).toISOString(), { setZone: true });
      const sunsetDt = DateTime.fromISO(new Date(sunTimes.sunset).toISOString(), { setZone: true });

      sunrise = {
        h: sunriseDt.toFormat('HH'),
        m: sunriseDt.toFormat('mm'),
      };
      sunset = {
        h: sunsetDt.toFormat('HH'),
        m: sunsetDt.toFormat('mm'),
      };
    }

    return ({
      sunrise: parseInt(sunrise.h, 10) * 60 + parseInt(sunrise.m, 10),
      sunset: parseInt(sunset.h, 10) * 60 + parseInt(sunset.m, 10),
    });
  }

  static removeTrailingBrackets(message: string): string {
    return message.replace(/^{/, '').replace(/}$/, '');
  }

  static getHours(): { title: string, key: string }[] {
    const timeList: { title: string, key: string }[] = [];

    for (let i = 0, hour = 12; i < 24; i += 1) {
      timeList.push({ title: `${hour}${i < 12 ? 'am' : 'pm'}`, key: (i + 1).toString() });

      if (hour === 12) {
        hour = 1;
      } else {
        hour += 1;
      }
    }

    return timeList;
  }

  static getDateStringArrayByGranularity(startDate: string, endDate: string, granularity: string): string[] {
    const enumerateBetweenDates = (startDateP: string, endDateP: string, format: string, duration: DurationInput) => {
      const dates: string[] = [];
      const endDateUtc = DateTime.fromFormat(endDateP, "yyyy-MM-dd'T'HH:mm:ss");
      let tmpDate = DateTime.fromFormat(startDateP, "yyyy-MM-dd'T'HH:mm:ss");
      dates.push(tmpDate.toFormat(format));
      while (tmpDate.plus(duration) <= endDateUtc) {
        tmpDate = tmpDate.plus(duration);
        dates.push(tmpDate.toFormat(format));
      }
      return dates;
    };
    let results: string[];
    if (granularity === '15_MIN') {
      results = enumerateBetweenDates(startDate, endDate, 'yyyy-MM-dd HH:mm:00', { minutes: 15 });
    } else if (granularity === 'HOUR') {
      results = enumerateBetweenDates(startDate, endDate, 'yyyy-MM-dd HH:00:00', { hour: 1 });
    } else if (granularity === 'DAY') {
      results = enumerateBetweenDates(startDate, endDate, 'yyyy-MM-dd HH:00:00', { day: 1 });
    } else {
      results = enumerateBetweenDates(startDate, endDate, 'yyyy-MM-dd HH:00:00', { month: 1 });
    }
    return results;
  }

  static fillEmptyGapsInEnergyConsumptionData(energyData: EnergyRecord[], startDate: string, endDate: string, granularity: string, timeZone: string): EnergyRecord[] {
    const dates = this.getDateStringArrayByGranularity(startDate, endDate, granularity);
    const energyDataMap: Map<string, EnergyRecord> = energyData.reduce((map: Map<string, EnergyRecord>, record: EnergyRecord) => {
      if (map.has(record.tick_datetime) && (map.get(record.tick_datetime) as FilledEnergyRecord)?.energy_consumption >= 0) {
        const newRecord: FilledEnergyRecord = { ...record as FilledEnergyRecord };
        const existingRecord = map.get(record.tick_datetime) as FilledEnergyRecord;
        const estimation = newRecord.overall_estimation === 'Yes' ? 'Yes' : existingRecord.overall_estimation;

        map.set(record.tick_datetime, {
          ...existingRecord,
          overall_estimation: estimation,
          energy_consumption: existingRecord.energy_consumption + newRecord.energy_consumption,
        });
      } else {
        map.set(record.tick_datetime, record);
      }
      return map;
    }, new Map());
    return dates.map((date) => {
      const newItem = energyDataMap.get(date) as EnergyRecord;
      return newItem || { tick_datetime: date };
    });
  }

  static calculateDateParams(granularity: string, startDate: DateTime, endDate: DateTime): {start: string, end: string} {
    const isSameDay = (d1: DateTime, d2: DateTime) => d1.startOf('day').equals(d2.startOf('day'));
    const format = (d: DateTime) => d.toFormat("yyyy-MM-dd'T'HH:mm:ss");
    const todayMoment = DateTime.fromMillis(Date.now());

    let start: string;
    let end: string;
    if (granularity === 'MONTH') {
      start = startDate.toFormat("yyyy-MM-01'T'00:00:00");
      end = endDate.toFormat("yyyy-MM-dd'T'23:59:59");
    } else {
      start = format(startDate.startOf('day'));
      if (isSameDay(todayMoment, endDate)) {
        end = format(todayMoment);
      } else {
        end = format(endDate.endOf('day'));
      }
    }

    return {
      start,
      end,
    };
  }

  static calculateAnalysisDateParams(granularity: string, startDate: DateTime, endDate: DateTime): {start: string, end: string} {
    const isSameDay = (d1: DateTime, d2: DateTime) => d1.startOf('day').equals(d2.startOf('day'));
    const format = (d: DateTime) => d.toFormat("yyyy-MM-dd'T'HH:mm:ss");
    const todayMoment = DateTime.fromMillis(Date.now());

    let start: string;
    let end: string;
    if (granularity === 'MONTH') {
      start = startDate.toFormat("yyyy-MM-01'T'00:00:00");
      end = endDate.toFormat("yyyy-MM-dd'T'23:59:59");
    } else {
      start = format(startDate.startOf('day'));
      if (isSameDay(todayMoment, endDate)) {
        end = format(todayMoment);
      } else {
        end = format(endDate.endOf('day'));
      }
    }
    start = start.concat('Z');
    end = end.concat('Z');
    return {
      start,
      end,
    };
  }

  static createEnergyPayload(startDate: DateTime, endDate: DateTime, selectedChartBase: ChartBase, selectedSite: SelectedSite, site: SiteObject): EnergyQueryPayload {
    const payload = { timePeriod: { start: '', end: '' }, timeZone: site?.time_zone } as EnergyQueryPayload;
    const { days } = endDate.diff(startDate, 'days');

    switch (selectedChartBase.type) {
      case 'LIGHTING':
        payload.lightingGroupId = selectedChartBase.group.groupId;
        payload.siteId = selectedSite.id;
        break;
      case 'ORGANIZATIONAL':
        payload.groupId = selectedChartBase.group.groupId;
        payload.siteId = selectedSite.id;
        break;
      case 'nodeList':
        payload.nodeIds = selectedChartBase.selectedNodes?.map((n) => n.nodeid);
        payload.siteId = selectedSite.id;
        break;
      case 'nodeid':
        payload.nodeIds = [selectedChartBase.selectedNode.nodeid];
        payload.siteId = selectedSite.id;
        break;
      default:
        payload.siteId = selectedSite.id;
        break;
    }

    switch (true) {
      case (days <= 1):
        payload.granularity = '15_MIN';
        break;
      case (days <= 3):
        payload.granularity = 'HOUR';
        break;
      case (days <= 90):
        payload.granularity = 'DAY';
        break;
      default:
        payload.granularity = 'MONTH';
        break;
    }

    payload.timePeriod = this.calculateDateParams(payload.granularity, startDate, endDate);

    return payload;
  }

  // eslint-disable-next-line max-len
  static createAnalysisDashboardPayload(startDate: DateTime, endDate: DateTime, selectedChartBase: ChartBase, selectedSite: SelectedSite, site: SiteObject): AnalysisDashboardQueryPayload {
    const payload = { timePeriod: { start: '', end: '' }, timeZone: site?.time_zone } as AnalysisDashboardQueryPayload;
    const { days } = endDate.diff(startDate, 'days');

    switch (selectedChartBase.type) {
      case 'LIGHTING':
        payload.targetId = selectedChartBase.group.groupId;
        payload.targetType = 'GROUP';
        break;
      case 'ORGANIZATIONAL':
        payload.targetId = selectedChartBase.group.groupId;
        payload.targetType = 'GROUP';
        break;
      case 'nodeList':
        payload.nodeIds = selectedChartBase.selectedNodes;
        payload.targetType = 'NODES';
        break;
      case 'nodeid':
        payload.nodeIds = [selectedChartBase.selectedNode.nodeid];
        payload.targetType = 'NODES';
        break;
      default:
        payload.nodeIds = [];
        payload.targetType = 'NODES';
        break;
    }

    switch (true) {
      case (days <= 1):
        // payload.granularity = '15_MIN';
        payload.granularity = 'RAW';
        break;
      case (days <= 3):
        // payload.granularity = 'HOUR';
        payload.granularity = 'RAW';
        break;
      case (days <= 90):
        // payload.granularity = 'DAY';
        payload.granularity = 'RAW';
        break;
      default:
        payload.granularity = 'RAW';
        break;
    }

    payload.timePeriod = this.calculateAnalysisDateParams(payload.granularity, startDate, endDate);

    return payload;
  }

  static getApiHost(): string {
    return window.NSN.apiURL?.replace('https://', '').replace('/v3.0', '') || '';
  }

  static getUserInfo(): Record<string, any> {
    const userInfoStr = sessionStorage.getItem('userInfo');
    return userInfoStr ? JSON.parse(userInfoStr) : {};
  }

  static getUserID(): string {
    return Utils.getUserInfo().id;
  }

  static getUserOrgId(): string {
    return Utils.getUserInfo().user.orgid;
  }

  static getUserRole(): string {
    const userInfo = Utils.getUserInfo();
    if (userInfo.authorization) {
      return userInfo.authorization[0]?.type;
    }
    return '';
  }

  static isVerizonUser(): boolean {
    return ['sensity_admin', 'sensity_user', 'sensity_read_only'].includes(Utils.getUserRole().toLowerCase());
  }

  static isVerizonUserorAdminUser(): boolean {
    return ['sensity_admin', 'sensity_user', 'sensity_read_only', 'partner_admin', 'end_user_admin'].includes(Utils.getUserRole().toLowerCase());
  }

  static isSensityUserAdmin(): boolean {
    return ['sensity_admin', 'sensity_user'].includes(Utils.getUserRole().toLowerCase());
  }

  static isSensityUserOrAdminUser(): boolean {
    return ['sensity_admin', 'sensity_user', 'partner_admin', 'end_user_admin'].includes(Utils.getUserRole().toLowerCase());
  }

  static isAdminUser(): boolean {
    return ['sensity_admin', 'partner_admin', 'end_user_admin'].includes(Utils.getUserRole().toLowerCase());
  }

  static isAdminOrPartnerAdmin(): boolean {
    return ['sensity_admin', 'sensity_user', 'partner_admin'].includes(Utils.getUserRole().toLowerCase());
  }

  static isSensityAdmin(): boolean {
    return Utils.getUserRole().toLowerCase() === 'sensity_admin';
  }

  static isSensityUser(): boolean {
    return Utils.getUserRole().toLowerCase() === 'sensity_user';
  }

  static isSensityReadOnly(): boolean {
    return Utils.getUserRole().toLowerCase() === 'sensity_read_only';
  }

  static isPartnerUserAdmin(): boolean {
    return Utils.getUserRole().toLowerCase() === 'partner_admin';
  }

  static isEndUserAdmin(): boolean {
    return Utils.getUserRole().toLowerCase() === 'end_user_admin';
  }

  static isNonReadOnly(): boolean {
    return ['sensity_admin', 'sensity_user', 'partner_admin', 'partner_lighting_user', 'end_user_admin', 'end_user_lighting_user'].includes(Utils.getUserRole().toLowerCase());
  }

  static getUserRolesByType(type: 'sensity' | 'partner' | 'partner_terrago' | 'account' | 'account_terrago'): SelectBoxItemType[] {
    const roles = {
      sensity: [
        { title: Utils.getUserRoleTitle('sensity_admin'), key: 'sensity_admin' },
        { title: Utils.getUserRoleTitle('sensity_user'), key: 'sensity_user' },
        { title: Utils.getUserRoleTitle('sensity_read_only'), key: 'sensity_read_only' },
      ],
      partner: [
        { title: Utils.getUserRoleTitle('partner_admin'), key: 'partner_admin' },
        { title: Utils.getUserRoleTitle('partner_lighting_user'), key: 'partner_lighting_user' },
        { title: Utils.getUserRoleTitle('partner_read_only'), key: 'partner_read_only' },
      ],
      partner_terrago: [
        { title: Utils.getUserRoleTitle('partner_admin'), key: 'partner_admin' },
        { title: Utils.getUserRoleTitle('partner_lighting_user'), key: 'partner_lighting_user' },
        { title: Utils.getUserRoleTitle('partner_read_only'), key: 'partner_read_only' },
        { title: Utils.getUserRoleTitle('TERRAGO_API_APP'), key: 'TERRAGO_API_APP' },
      ],
      account: [
        { title: Utils.getUserRoleTitle('end_user_admin'), key: 'end_user_admin' },
        { title: Utils.getUserRoleTitle('end_user_lighting_user'), key: 'end_user_lighting_user' },
        { title: Utils.getUserRoleTitle('end_user_read_only'), key: 'end_user_read_only' },
      ],
      account_terrago: [
        { title: Utils.getUserRoleTitle('end_user_admin'), key: 'end_user_admin' },
        { title: Utils.getUserRoleTitle('end_user_lighting_user'), key: 'end_user_lighting_user' },
        { title: Utils.getUserRoleTitle('end_user_read_only'), key: 'end_user_read_only' },
        { title: Utils.getUserRoleTitle('TERRAGO_API_APP'), key: 'TERRAGO_API_APP' },
      ],
    };

    return roles[type];
  }

  static getUserRoleTitle(userRole: string = Utils.getUserRole()): string {
    const roleTitles: Record<string, string> = {
      SENSITY_ADMIN: 'Verizon admin',
      SENSITY_USER: 'Verizon user',
      SENSITY_READ_ONLY: 'Verizon read-only',
      TERRAGO_API_APP: 'Terrago api app',
      PARTNER_ADMIN: 'Partner admin',
      PARTNER_LIGHTING_USER: 'Partner user',
      PARTNER_READ_ONLY: 'Partner read-only',
      END_USER_ADMIN: 'Account admin',
      END_USER_LIGHTING_USER: 'Account user',
      END_USER_READ_ONLY: 'Account read-only',
    };

    const fallbackUserRole = `${userRole.charAt(0).toUpperCase()}${userRole.slice(1)}`.split('_').join(' ');
    return roleTitles[userRole.toUpperCase()] || fallbackUserRole || '';
  }

  static getUserName(): string {
    return Utils.getUserInfo()?.name || '';
  }

  static getFixtureType(): SelectBoxItemType[] {
    const fixtureType = [
      { title: 'Acorn', key: 'acorn' },
      { title: 'Bollard', key: 'bollard' },
      { title: 'Camera', key: 'camera' },
      { title: 'Canopy', key: 'canopy' },
      { title: 'Citadel 818', key: 'citadel 818' },
      { title: 'Citadel Wallpack', key: 'citadel wallpack' },
      { title: 'Cuscutlan', key: 'cuscutlan' },
      { title: 'Cobra Head', key: 'cobra head' },
      { title: 'Floodlight', key: 'floodlight' },
      { title: 'Garage Canopy', key: 'garage canopy' },
      { title: 'Globe', key: 'globe' },
      { title: 'Highbay', key: 'highbay' },
      { title: 'High Mast', key: 'highmast' },
      { title: 'Highbay 3 petal', key: 'highbay 3-petal' },
      { title: 'Highbay 4 petal', key: 'highbay 4-petal' },
      { title: 'Highbay 6 petal', key: 'highbay 6-petal' },
      { title: 'Highbay 8 petal', key: 'highbay 8-petal' },
      { title: 'Highbay 9 petal', key: 'highbay 9-petal' },
      { title: 'Highbay 12 petal', key: 'highbay 12-petal' },
      { title: 'Lantern', key: 'lantern' },
      { title: 'LMP', key: 'lmp' },
      { title: 'Pendant', key: 'pendant' },
      { title: 'Post Top', key: 'post top' },
      { title: 'Roadway', key: 'roadway' },
      { title: 'Shoebox', key: 'shoebox' },
      { title: 'Shoebox 418', key: 'shoebox 418' },
      { title: 'Spotlights', key: 'spotlights' },
      { title: 'Streetlamp1', key: 'streetlamp1' },
      { title: 'Teardrop', key: 'teardrop' },
      { title: 'Wallpack', key: 'wallpack' },
    ];
    return fixtureType;
  }

  static copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static async sendFormData(path: string, nodeList?: Map<string, NodeObject> | string[], file?: File): Promise<Response> {
    const formData = new FormData();

    if (nodeList) {
      let nodeListStr = 'nodeid\n';
      nodeList.forEach((n: NodeObject | string) => {
        const id = typeof n === 'string' ? n : n.nodeid;
        nodeListStr += `${id}\n`;
      });

      const blob = new Blob([nodeListStr], { type: 'text/csv' });
      formData.append('csvNodeList', blob);
    } else if (file) {
      formData.append('csvNodeList', file);
    }
    const url = `${window.NSN.apiURLV4}${path}`;

    const netsenseCsrfToken: string = localStorage.getItem(Utils.csrfTokenName) || '';
    const headers: HeadersInit = new Headers();

    headers.set('X-CSRF-Token', netsenseCsrfToken);
    headers.set('x-lighting-ui', 'true');
    // eslint-disable-next-line prefer-template
    headers.set(Utils.JSONWebTokenName, 'bearer ' + localStorage.getItem(Utils.JSONWebTokenName) || '');

    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers,
      body: formData,
    });

    return response;
  }

  static async uploadGISfile(path: string, file?: File): Promise<Response> {
    // const formData = new FormData();

    //    if (file) {
    //      formData.append('gisDataExcel', file);
    //    }

    const url = `${window.NSN.apiURLV4}${path}`;
    const netsenseCsrfToken: string = localStorage.getItem(Utils.csrfTokenName) || '';
    const headers: HeadersInit = new Headers();

    headers.set('X-CSRF-Token', netsenseCsrfToken);
    // eslint-disable-next-line prefer-template
    headers.set(Utils.JSONWebTokenName, 'bearer ' + localStorage.getItem(Utils.JSONWebTokenName) || '');
    headers.set('x-lighting-ui', 'true');

    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers,
      body: file,
    });

    return response;
  }

  static consolidateDuplicationErrorMessage(text : string) : string {
    if (!text.includes('were saved before')) {
      return text;
    }
    const collator = new Intl.Collator([], { numeric: true });
    const splittedMessage = text.split('The slid: \'');
    const startMessage = splittedMessage.shift();
    const slidArray: Array<{slid: string, lampseq: string}> = [];
    let middleMessage = '';
    let result = '';

    splittedMessage.forEach((element) => {
      if (element.includes('were saved before')) {
        const slid = element.substring(0, element.indexOf('\''));
        const lampseqStartIndex = (element.indexOf('seq: ')) + 6;
        const lampseq = element.substring(lampseqStartIndex, element.indexOf('\'', lampseqStartIndex));
        slidArray.push({ slid, lampseq });
        const otherErrorCheck = element.substring(element.indexOf('were saved before') + 18);
        if (otherErrorCheck.length > 0) {
          middleMessage = middleMessage.concat(`${otherErrorCheck}, `);
        }
      }
    });
    result = middleMessage + startMessage;

    if (slidArray.length === 1) {
      result = result.concat(`The slid '${slidArray[0].slid}' with lampseq '${slidArray[0].lampseq}' was saved before.`);
    } else {
      slidArray.sort((a, b) => collator.compare(a.slid, b.slid));
      result = result.concat(`Total of ${slidArray.length} fixtures between slid '${slidArray[0].slid}' with lampseq '${slidArray[0].lampseq}' and slid '${slidArray[slidArray.length - 1].slid}'`
      + ` with lampseq '${slidArray[slidArray.length - 1].lampseq}' were saved before.`);
    }

    return result;
  }

  static capitalizeString(text: string): string {
    return `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;
  }

  static addOrdinalSuffix(i?: number): string {
    if (!i) {
      return '';
    }

    const j = i % 10;
    const k = i % 100;

    if (j === 1 && k !== 11) {
      return `${i}st`;
    }

    if (j === 2 && k !== 12) {
      return `${i}nd`;
    }

    if (j === 3 && k !== 13) {
      return `${i}rd`;
    }

    return `${i}th`;
  }

  static getNextSortDir(sorting: TableSorting): string {
    let nextSortDir = 'asc';

    switch (sorting.dir) {
      case '':
        nextSortDir = 'asc';
        break;
      case 'asc':
        nextSortDir = 'desc';
        break;
      case 'desc':
        nextSortDir = '';
        break;
      default:
        nextSortDir = 'asc';
        break;
    }

    return nextSortDir;
  }

  static filterData<T>(filtering: TableFiltering, data: T[]): T[] {
    let tmpData: T[] = [...data];

    if (Object.keys(filtering).length) {
      Object.keys(filtering).forEach((filterCol) => {
        tmpData = data.filter(
          (item: any) => {
            let filteredItem = '';

            if (item.customAttributes && filterCol.startsWith('customAttributes_')) {
              const attrKey = filterCol.replace('customAttributes_', '');

              if (item.customAttributes[attrKey]) {
                filteredItem = item.customAttributes[attrKey];
              }
            } else {
              filteredItem = Object.prototype.hasOwnProperty.call(item, `${filterCol}TableFilter`) ? item[`${filterCol}TableFilter`] : item[filterCol];
            }

            const searchText = filtering[filterCol]?.toLowerCase() || '';

            return filteredItem.toLowerCase().includes(searchText);
          },
        );
      });
    }

    return tmpData;
  }

  static sortData<T>(sorting: TableSorting, data: T[]): T[] {
    const tmpData: T[] = [...data];

    if (sorting.by !== '' && sorting.dir !== '') {
      tmpData.sort((a: any, b: any) => {
        let valA = '';
        let valB = '';

        if (sorting.by.startsWith('customAttributes_')) {
          const attrKey = sorting.by.replace('customAttributes_', '');

          if (a.customAttributes && a.customAttributes[attrKey]) {
            valA = a.customAttributes[attrKey];
          }

          if (b.customAttributes && b.customAttributes[attrKey]) {
            valB = b.customAttributes[attrKey];
          }
        } else {
          valA = Object.prototype.hasOwnProperty.call(a, `${sorting.by}TableSort`)
            ? a[`${sorting.by}TableSort`]
            : a[sorting.by];
          valB = Object.prototype.hasOwnProperty.call(b, `${sorting.by}TableSort`)
            ? b[`${sorting.by}TableSort`]
            : b[sorting.by];
        }

        if (valA < valB) {
          return sorting.dir === 'asc' ? -1 : 1;
        }

        if (valA > valB) {
          return sorting.dir === 'asc' ? 1 : -1;
        }

        return 0;
      });
    }

    return tmpData;
  }

  static filterAndSortTable<T>(filtering: TableFiltering, sorting: TableSorting, data: T[]): T[] {
    return this.sortData(sorting, this.filterData(filtering, data));
  }

  static sort(sorting: TableSorting, valA: any, valB: any): number {
    if (valA < valB) {
      return sorting.dir === 'asc' ? -1 : 1;
    }

    if (valA > valB) {
      return sorting.dir === 'asc' ? 1 : -1;
    }

    return 0;
  }

  static filterAndSortMap<T>(filtering: TableFiltering, sorting: TableSorting, data: Map<string, T[]>): Map<string, T[]> {
    let arr = Array.from(data.entries());

    if (filtering.key) {
      arr = arr.filter((val) => val[0].toLowerCase().includes(filtering.key?.toLowerCase() || ''));
    }

    if (sorting.by === 'count') {
      arr = arr.sort((a, b) => this.sort(sorting, a[1].length, b[1].length));
    } else if (sorting.by === 'key') {
      arr = arr.sort((a, b) => this.sort(sorting, a[0], b[0]));
    }

    return new Map(arr);
  }
}

export default Utils;
